import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

import Backdrop from "../Misc/Backdrop";
import {
  useLastAlternativeId,
  useLastAlternativeIdUpdate,
  useAlternativeOption,
  useAlternativeOptionUpdate,
} from "../../context/AlternativeContext";
//animation
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};

const AddAlternativeModal = ({
  handleClose,
  allOptions,
  setAlternatives,
  allAlternatives,
  allCriterias,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
  allScenarios,
}) => {
  //local state
  const [altName, setAltName] = useState("");
  const [altCode, setAltCode] = useState("");
  const [description, setDescription] = useState("");

  const lastAlternativeId = useLastAlternativeId();
  const setLastAlternativeId = useLastAlternativeIdUpdate();

  const allAltOps = useAlternativeOption();
  const setAltOps = useAlternativeOptionUpdate();

  //methods
  const submit = async () => {
    try {
      const optionIds = allOptions.map((option) => {
        return option.id;
      });
      if (
        altName.trim() === "" ||
        altCode.trim() === "" ||
        description.trim() === ""
      ) {
        alert("Missing require fields");
      } else {
        const id = parseInt(localStorage.getItem("userId"));
        //update global state
        const currentId = lastAlternativeId;
        if (currentId) {
          let updatedAlternatives = allAlternatives;
          updatedAlternatives.push({
            id: lastAlternativeId + 1,
            creatorId: id,
            name: altName,
            code: altCode,
            description: description,
          });
          setAlternatives(updatedAlternatives);
          const updatedAltOps = allAltOps;

          for (const id of optionIds) {
            updatedAltOps.push({
              altId: lastAlternativeId + 1,
              optId: id,
              checked: false,
            });
          }
          setAltOps(updatedAltOps);
          const updatedEvalAlt = allEvaluatedAlternatives;
          for (const criteria of allCriterias) {
            updatedEvalAlt.push({
              altId: lastAlternativeId + 1,
              critId: criteria.id,
              score: "",
              description: "",
              scenarioId: null,
              deleted: false,
            });
            if (allScenarios.length > 0) {
              for (const scenario of allScenarios) {
                updatedEvalAlt.push({
                  altId: lastAlternativeId + 1,
                  critId: criteria.id,
                  score: "",
                  description: "",
                  scenarioId: scenario.id,
                  deleted: false,
                });
              }
            }
          }
          setEvaluatedAlternatives(updatedEvalAlt);
          /////////////
          handleClose();
        }
        //save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation createAlternative{ createAlternative(data:{creatorId:${id}, name:"${altName}", code:"${altCode}", description:"${description}", options:[${optionIds}]}){id,creator{id},name,code,description}}`,
        });
        let req = await fetch(
          "https://intplantoolapi.azurewebsites.net/api/alternative",
          {
            body: body,
            method: "POST",
          }
        );

        let res = await req.text();
        setLastAlternativeId(JSON.parse(res).data.createAlternative.id);
        const createdId = JSON.parse(res).data.createAlternative.id;
        console.log("created", createdId);
        if (!currentId) {
          //set id in globabl state
          let updatedAlternatives = allAlternatives;
          updatedAlternatives.push({
            id: createdId,
            creatorId: id,
            name: altName,
            code: altCode,
            description: description,
          });
          setAlternatives(updatedAlternatives);
          const updatedAltOps = allAltOps;

          for (const id of optionIds) {
            updatedAltOps.push({
              altId: createdId,
              optId: id,
              checked: false,
            });
          }
          setAltOps(updatedAltOps);
          const updatedEvalAlt = allEvaluatedAlternatives;
          for (const criteria of allCriterias) {
            updatedEvalAlt.push({
              altId: createdId,
              critId: criteria.id,
              score: "",
              description: "",
              scenarioId: null,
              deleted: false,
            });
            if (allScenarios.length > 0) {
              for (const scenario of allScenarios) {
                updatedEvalAlt.push({
                  altId: createdId,
                  critId: criteria.id,
                  score: "",
                  description: "",
                  scenarioId: scenario.id,
                  deleted: false,
                });
              }
            }
          }
          setEvaluatedAlternatives(updatedEvalAlt);
          /////////////
          handleClose();
        }

        setAltName("");
        setAltCode("");
        setDescription("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop>
      <motion.div
        className=" rounded w-[40rem] bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-12 flex flex-col gap-4 ">
          <h2 className=" text-center text-2xl font-semibold ">
            Create alternative{" "}
          </h2>

          <div className="name flex flex-col">
            <label className="text-xl">Alternative Name</label>
            <input
              type="text"
              className="rounded h-text-input border-2 border-gray-300 dark:text-black"
              // placeholder=" Alternative name"
              onChange={(e) => setAltName(e.target.value)}
            />
          </div>
          <div className="name flex flex-col text-xl">
            <label className="">Alternative Code</label>
            <input
              type="text"
              className="rounded h-text-input border-2 border-gray-300 dark:text-black"
              // placeholder="Alternative code"
              onChange={(e) => setAltCode(e.target.value)}
            />
          </div>
          <div className="name flex flex-col">
            <label className="text-xl">Description</label>
            <textarea
              rows="4"
              className="rounded border-2 border-gray-300 dark:text-black"
              // placeholder=" Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-center gap-2">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center h-button w-full"
              onClick={submit}
            >
              SAVE
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-gray-400 dark:bg-wc-grey text-white flex items-center justify-center h-button w-full"
              onClick={handleClose}
            >
              CANCEL
            </motion.button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddAlternativeModal;
