import React from "react";
import Backdrop from "../Misc/Backdrop";
import { motion } from "framer-motion";
import { useState } from "react";

//animations
const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const UpdateEvaluation = ({
  handleClose,
  setCriterias,
  criteria,
  id,
  allCriterias,
}) => {
  //local state
  const [name, setName] = useState(criteria[0].name);
  const [description, setDescription] = useState(criteria[0].description);
  const [dataSource, setDataSource] = useState(criteria[0].data_source);
  const [metric, setMetric] = useState(criteria[0].metric);
  const [scoringLevel, setScoringLevel] = useState(criteria[0].scoring_level);

  const [scores, setScores] = useState(criteria[0].scoring_points);
  const [scoringDesc, setScoringDesc] = useState(
    criteria[0].scoring_assignment
  );
  //methods
  const assignScores = (num) => {
    setScoringLevel(num);
    const increment = 1 / parseInt(num - 1);
    let divScore = 0;
    const tempArr = [];
    for (let i = 0; i < num; i++) {
      tempArr.push(parseFloat(divScore.toFixed(2)));
      divScore += increment;
    }
    setScores(tempArr);
  };

  const updateScoringDesc = (index, e) => {
    const tempArr = [...scoringDesc];
    tempArr[index] = e;
    setScoringDesc(tempArr);
  };

  const submit = async (e) => {
    try {
      e.preventDefault();
      //convert state to accebtable graphql query string params
      const str_score = scores.map((score, index) => {
        return score.toString();
      });
      const scoring_points = str_score.map((el) => {
        return `"${el}"`;
      });

      const scoring_slice = scoringDesc.slice(0, parseInt(scoringLevel));

      const scoring_assignment = scoring_slice.map((el) => {
        return `"${el}"`;
      });
      /////////////

      //updated global state
      let updatedCriterias = allCriterias;
      for (const [index, crit] of updatedCriterias.entries()) {
        if (crit.id === id) {
          updatedCriterias[index] = {
            id: id,
            name: name,
            description: description,
            data_source: dataSource,
            metric: metric,
            scoringLevel: parseInt(scoringLevel),
            scoring_points: str_score,
            scoring_assignment: scoringDesc,
            weight: crit.weight,
          };
        }
      }

      console.log("update criterias: ", {
        id: id,
        name: name,
        description: description,
        data_source: dataSource,
        metric: metric,
        scoringLevel: parseInt(scoringLevel),
        scoring_points: str_score,
        scoring_assignment: scoringDesc,
        weight: null,
      });

      setCriterias(updatedCriterias);
      ////////
      handleClose();
      ////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation updateCriteria{updateCriteria(data:{id:${id}, name:"${name}", description:"${description}", data_source:"${dataSource}", metric:"${metric}", scoring_level:${parseInt(
          scoringLevel
        )}, scoring_points:[${scoring_points}], scoring_assignment:[${scoring_assignment}], weight:${null}}){name}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/criteria", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="rounded-xl bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto overflow-auto w-fit p-12 flex flex-col gap-6 "
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className=" rounded w-fit p-2 flex gap-2 items-center justify-center ">
          <h2 className="text-2xl ">Update evaluation </h2>
        </div>

        <div className="flex flex-col">
          <label>Evaluation criteria name</label>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            defaultValue={name}
            type="text"
            className="rounded h-text-input text-black border-2 border-gray-300"
            placeholder=" Enter critereia name"
          />
        </div>
        <div className="flex flex-col">
          <label>Evaluation criteria description</label>
          <textarea
            rows="3"
            type="text"
            defaultValue={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            className="rounded h-text-input text-black border-2 border-gray-300"
            placeholder=" Enter criteria description "
          />
        </div>
        <div className="flex gap-6 items-center">
          <label> Qualitative or Quantitiave: </label>
          <div className="flex gap-2 items-center">
            <label>Qualitative</label>
            <input
              type="checkbox"
              className="checkbox text-black border-2 border-gray-300"
              checked={metric === "Qualitative"}
              onChange={() => {
                setMetric("Qualitative");
              }}
            />
            <label>Quantitative</label>
            <input
              type="checkbox"
              className="checkbox text-black border-2 border-gray-300"
              checked={metric === "Quantitative"}
              onChange={() => {
                setMetric("Quantitative");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label>Data source</label>
          <select
            className="h-text-input text-black rounded border-2 border-gray-300 "
            onChange={(e) => {
              setDataSource(e.target.value);
            }}
            defaultValue={dataSource}
          >
            <option defaultValue>Select automated or manual</option>
            <option value="AUTOMATED">Automated</option>
            <option value="MANUAL">Manual</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label>Number of scoring levels</label>
          <select
            className="h-text-input text-black rounded border-2 border-gray-300"
            onChange={(e) => {
              assignScores(e.target.value);
            }}
            defaultValue={scoringLevel}
          >
            <option defaultValue>Select number between 1-5</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <label>Scoring assignments(points)</label>
          {scores.map((score, index) => {
            return (
              <div
                className="flex gap-2 items-center justify-between"
                key={index}
              >
                <label className="basis-1/12">{score}: </label>
                <textarea
                  rows="3"
                  type="text"
                  defaultValue={scoringDesc[index]}
                  onChange={(e) => {
                    updateScoringDesc(index, e.target.value);
                  }}
                  className="rounded h-text-input text-black basis-10/12 w-full border-2 border-gray-300"
                  placeholder=" Describe criteria to get this score "
                />
              </div>
            );
          })}
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button text-xl"
            onClick={submit}
          >
            Save
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateEvaluation;
