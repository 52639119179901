import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const DeleteModal = ({ deleteFunc, id, heading, text, handleClose }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="rounded-xl max-w-lg min-w-sm bg-gray-200 dark:bg-wc-gradient dark:text-white bg-cover text-black
        shadow-md font-roboto p-12 flex flex-col gap-4"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-xl">{heading}</h2>
        <p className="text-md">{text}</p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="rounded-md p-2 bg-wc-green text-white dark:bg-wc-grey flex items-center justify-center m-2 h-button text-xl"
          onClick={() => {
            console.log(deleteFunc);
            deleteFunc(id);
            handleClose();
          }}
        >
          Confirm delete
        </motion.button>
      </motion.div>
    </Backdrop>
  );
};

export default DeleteModal;
