import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";

const UpdateUserModal = ({
  handleClose,
  user_id,
  firstName,
  lastName,
  email,
  phone,
  role,
  deleted,
  setUsers,
  allUsers,
}) => {
  //local state
  const [updateFirstName, setUpdateFirstName] = useState(firstName);
  const [updateLastName, setUpdateLastName] = useState(lastName);
  const [updateEmail, setUpdateEmail] = useState(email);
  const [updatePhone, setUpdatePhone] = useState(phone);
  const [updateRole, setUpdateRole] = useState(role);
  const [updateDeleted, setUpdateDeleted] = useState(deleted);
  const slideIn = {
    hidden: { x: "100vh", opacity: 0 },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: { x: "-100vh", opacity: 0 },
  };
  const submit = async () => {
    try {
      if (
        user_id === null ||
        updateFirstName.trim() === "" ||
        updateLastName.trim() === "" ||
        updateEmail.trim() === "" ||
        updatePhone.trim() === "" ||
        updateRole.trim() === "" ||
        updateDeleted === null
      ) {
        alert("Missing a required field");
      } else {
        /////udpate global user state
        const deletedConvert = updateDeleted === "true" ? true : false;
        let updatedUsers = allUsers;
        for (const [index, user] of updatedUsers.entries()) {
          if (user.id === user_id) {
            updatedUsers[index] = {
              id: user_id,
              firstName: updateFirstName,
              lastName: updateLastName,
              email: updateEmail,
              phone: updatePhone,
              role: updateRole,
              deleted: deletedConvert,
            };
          }
        }
        setUsers(updatedUsers);
        /////
        handleClose();
        ////save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation updateUser{ updateUser(data:{id:${user_id}, firstName:"${updateFirstName}", lastName:"${updateLastName}", email:"${updateEmail}", phone:"${updatePhone}", role:${updateRole}, deleted:${deletedConvert}}){firstName}}`,
        });

        await fetch("https://intplantoolapi.azurewebsites.net/api/user", {
          body: body,
          method: "POST",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="rounded-xl min-w-3/12 bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-12 flex flex-col gap-8 line-clamp-7 min-w-12">
          <div className=" rounded w-fit p-2 flex gap-2 items-center justify-center ">
            <h2 className="text-2xl ">Update user</h2>
          </div>

          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="rounded basis-6/12 h-text-input border-2 border-gray-300"
              placeholder=" First name"
              defaultValue={firstName}
              onChange={(e) => {
                setUpdateFirstName(e.target.value);
              }}
            />
            <input
              type="text"
              className="rounded basis-6/12 border-2 border-gray-300 h-text-input"
              placeholder=" Last name"
              defaultValue={lastName}
              onChange={(e) => {
                setUpdateLastName(e.target.value);
              }}
            />
          </div>
          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="email text-black rounded h-text-input border-2 border-gray-300 basis-6/12"
              placeholder=" email"
              defaultValue={email}
              onChange={(e) => {
                setUpdateEmail(e.target.value);
              }}
            />
            <input
              type="text"
              className="phone text-black rounded h-text-input border-2 border-gray-300 basis-6/12"
              placeholder=" Phone"
              defaultValue={phone}
              onChange={(e) => {
                setUpdatePhone(e.target.value);
              }}
            />
          </div>

          <select
            name="role"
            id="role"
            className="h-text-input text-black rounded border-2 border-gray-300"
            onChange={(e) => {
              setUpdateRole(e.target.value);
            }}
            defaultValue={role}
          >
            <option value="GUEST">Guest</option>
            <option value="USER">User</option>
            <option value="ADMIN">Admin</option>
            <option value="COUNTY">County</option>
          </select>
          <div className="flex flex-col gap-1">
            <label>User status</label>
            <select
              className="h-text-input text-black rounded border-2 border-gray-300"
              defaultValue={deleted}
              onChange={(e) => {
                setUpdateDeleted(e.target.value);
              }}
            >
              <option defaultValue>Set user status</option>
              <option value={false}>ACTIVE</option>
              <option value={true}>INACTIVE</option>
            </select>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button text-xl"
            onClick={submit}
          >
            Save
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateUserModal;
