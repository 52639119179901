import React from "react";
import { useState, useRef } from "react";

import Users from "./Users.js";
import DeleteModal from "../Misc/DeleteModal";
import UpdateModal from "./UpdateUserModal";

import { AiOutlineUserAdd } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

const AdminTable = ({
  addUser,
  closeAddUser,
  openAddUser,
  sidebarOpen,
  user_list,
  setUsers,
  allUsers,
}) => {
  const adminDeleteUser = async (id) => {
    try {
      ////update user global state
      let updatedUsers = allUsers;
      for (const [index, usr] of updatedUsers.entries()) {
        if (usr.id === id) {
          updatedUsers[index] = {
            id: id,
            firstName: usr.firstName,
            lastName: usr.lastName,
            email: usr.email,
            role: usr.role,
            phone: usr.phone,
            deleted: true,
          };
        }
      }
      setUsers(updatedUsers);
      /////
      ////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation deleteUser{deleteUser(id:${id}){id}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/user", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  //local state
  //delete
  const deleteId = useRef("");
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    setDeleteModal(true);
    deleteId.current = id;
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  //update
  const user_id = useRef(null);
  const firstName = useRef("");
  const lastName = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const role = useRef("");
  const deleted = useRef(null);
  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = (userInfo) => {
    user_id.current = userInfo.id;
    firstName.current = userInfo.firstName;
    lastName.current = userInfo.lastName;
    email.current = userInfo.email;
    phone.current = userInfo.phone;
    role.current = userInfo.role;
    deleted.current = userInfo.deleted;
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`admin-table dark:bg-gray-900 min-h-full duration-400 ease-in-out transition-spacing p-16 font-roboto flex gap-4 ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      {/* <div className="admin-table bg-white min-h-full ml-[120px] mr-[40px] duration-400 ease-in-out transition-spacing rounded shadow-md p-12 "> */}
      <AnimatePresence exitBeforeEnter initial={false}>
        {deleteModal && (
          <DeleteModal
            handleClose={closeDeleteModal}
            heading={"Are you sure you want to delete this user?"}
            text={
              "Admin will still be able to view the user in the admin table but the active status will be false, and user the will no longer be able to login."
            }
            deleteFunc={adminDeleteUser}
            id={deleteId.current}
          />
        )}
        {updateModal && (
          <UpdateModal
            handleClose={closeUpdateModal}
            user_id={user_id.current}
            firstName={firstName.current}
            lastName={lastName.current}
            email={email.current}
            phone={phone.current}
            role={role.current}
            deleted={deleted.current}
            setUsers={setUsers}
            allUsers={allUsers}
          />
        )}
      </AnimatePresence>
      <div className="flex justify-between h-[70rem] shadow-md rounded-md">
        <div className="flex flex-col gap-4 items-center w-fit text-blackshadow-lg rounded-md bg-white dark:bg-gray-700">
          <h2 className="text-2xl font-semibold dark:text-white bg-wc-green p-4 text-white rounded-t-md">
            Account Management
          </h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className=" rounded-md p-2 bg-wc-green text-white flex items-center justify-center w-38 h-button gap-2"
            onClick={() => (addUser ? closeAddUser() : openAddUser())}
          >
            <AiOutlineUserAdd size="24" /> Add User
          </motion.button>

          <hr className="w-10/12 text-black" />
          {user_list && (
            <div className="flex flex-col gap-4">
              <span className="text-black dark:text-white font-semibold tracking-wider">
                {user_list.length} users
              </span>
              <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-gray-800 bg-gray-100 dark:bg-gray-50 rounded-lg bg-opacity-50 flex gap-1 ">
                {/* {user_list.loading && <span>loading...</span>} */}
                {user_list && (
                  <span>
                    {user_list.filter((el) => el.role === "GUEST").length}
                  </span>
                )}
                Guest
              </div>
              <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 dark:bg-yellow-400 rounded-lg bg-opacity-50 flex gap-1">
                {/* {user_list.loading && <span>loading...</span>} */}
                {user_list && (
                  <span>
                    {user_list.filter((el) => el.role === "USER").length}
                  </span>
                )}
                USER
              </div>
              <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 dark:bg-green-400  rounded-lg bg-opacity-50 flex gap-1">
                {/* {user_list.loading && <span>loading...</span>} */}
                {user_list && (
                  <span>
                    {user_list.filter((el) => el.role === "ADMIN").length}
                  </span>
                )}
                ADMIN
              </div>
              <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-blue-800 bg-blue-200 dark:bg-blue-400 rounded-lg bg-opacity-50 flex gap-1">
                {/* {user_list.loading && <span>loading...</span>} */}
                {user_list && (
                  <span>
                    {user_list.filter((el) => el.role === "COUNTY").length}
                  </span>
                )}
                {/* county -> agency */}
                AGENCY
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-auto rounded-lg shadow-lg w-full h-[70rem] bg-white dark:bg-gray-700">
        <table className="min-w-full">
          <thead className="border-b-2 border-gray-200 bg-wc-green">
            <tr>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                User ID
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                Full Name
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                Email
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                Phone (Office)
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                User Type
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                Active
              </th>
              <th className="p-3 text-sm font-semibod tracking-wide text-left whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>

          {user_list && (
            <tbody className="divide-y">
              {user_list.map((user, index) => {
                return (
                  <Users
                    user={user}
                    index={index}
                    key={index}
                    openDeleteModal={openDeleteModal}
                    openUpdateModal={openUpdateModal}
                    allUsers={allUsers}
                  ></Users>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {/* pagination */}
      {/* <AdminTableFooter className="" /> */}
    </motion.div>
  );
};

export default AdminTable;
