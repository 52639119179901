import React, { useState } from "react";
import Scenario from "./Scenario";
import DeleteModal from "../Misc/DeleteModal";
import UpdateModal from "./UpdateScenarioModal";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineFileAdd } from "react-icons/ai";

const ScenarioTable = ({
  sidebarOpen,
  openAddScenario,
  allScenarios,
  setScenarios,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
}) => {
  const [deleteId, setDeleteId] = useState(null);

  //delete scenario
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const deleteScenario = async (id) => {
    try {
      //update scenario global state
      const updateScenarios = allScenarios.filter((el) => {
        return el.id !== id;
      });
      setScenarios(updateScenarios);
      const updatedEvalAlt = allEvaluatedAlternatives.filter((el) => {
        return el.scenarioId !== id;
      });
      setEvaluatedAlternatives(updatedEvalAlt);
      /////
      //save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation deleteScenario{ deleteScenario(id:${id}){id}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/scenario", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };
  //update scenario mutation
  const [updateId, setUpdateId] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = (id) => {
    setUpdateId(id);
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`scenario-table  dark:bg-gray-700 min-h-full  duration-400 ease-in-out transition-spacing font-roboto flex gap-4 p-16 ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      <div className="flex justify-between h-[70rem] w-[15rem] bg-white dark:bg-gray-700 rounded-md">
        <div className="flex flex-col items-center gap-4 w-fit text-black dark:text-white shadow-lg rounded-md">
          <h2 className="text-2xl font-semibold tracking-wide bg-wc-green p-4 text-white rounded-t-md">
            Scenario Summary
          </h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-3 bg-wc-green h-button text-white flex items-center justify-center w-38 gap-2"
            onClick={openAddScenario}
          >
            <AiOutlineFileAdd size="24" />
            Add Scenario
          </motion.button>

          <hr className="w-10/12 text-black" />

          {allScenarios && (
            <span className="text-black dark:text-white bg-blue-200 p-2 rounded-md">
              {`${allScenarios.length} ${
                allScenarios.length === 1 ? "scenario" : "scenarios"
              }`}
            </span>
          )}
        </div>
      </div>
      <div className="overflow-auto rounded-lg shadow-lg w-11/12  h-[70rem] bg-white dark:bg-gray-700">
        <table className="min-w-full shadow-md">
          <thead className="bg-wc-green border-b-2 border-gray-200 text-white">
            <tr>
              {/* <th className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center">
                Scenario Id
              </th> */}

              <th className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center">
                Name
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center">
                Description
              </th>

              <th className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {allScenarios &&
              allScenarios.map((scenario, index) => {
                return (
                  <Scenario
                    scenario={scenario}
                    openDeleteModal={openDeleteModal}
                    openUpdateModal={openUpdateModal}
                    key={index}
                    index={index}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      <AnimatePresence exitBeforeEnter initial={false}>
        {deleteModal && (
          <DeleteModal
            handleClose={closeDeleteModal}
            text={"Are you sure you want to delete this scenario?"}
            deleteFunc={deleteScenario}
            id={deleteId}
          />
        )}
        {updateModal && (
          <UpdateModal
            handleClose={closeUpdateModal}
            scenario={allScenarios.filter((el) => {
              return el.id === updateId;
            })}
            setScenarios={setScenarios}
            allScenarios={allScenarios}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ScenarioTable;
