import React, { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";
import {
  useLastScenarioIdUpdate,
  useLastScenarioId,
} from "../../context/ScenarioContext";
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddScenarioModal = ({
  handleClose,
  setScenarios,
  allScenarios,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
}) => {
  //local state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  //context
  const lastScenarioId = useLastScenarioId();
  const setLastScenarioId = useLastScenarioIdUpdate();
  //methods
  const submit = async () => {
    try {
      if (name === "" || description === "") alert("Missing require fields");
      else {
        //update scenario globabl state
        const currentId = lastScenarioId;
        if (currentId) {
          let updatedScenarios = allScenarios;
          updatedScenarios.push({
            id: lastScenarioId + 1,
            name: name,
            description: description,
          });
          setScenarios(updatedScenarios);

          let updatedEvalAlts = allEvaluatedAlternatives;
          let filteredEvalAlts = allEvaluatedAlternatives.filter((el) => {
            return el.id === null;
          });

          for (const el of filteredEvalAlts) {
            updatedEvalAlts.push({
              score: el.score,
              description: el.description,
              critId: el.critId,
              altId: el.altId,
              deleted: false,
              scenarioId: lastScenarioId + 1,
            });
          }
          setEvaluatedAlternatives(updatedEvalAlts);
          /////
          handleClose();
        }
        //save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation createScenario{createScenario(data:{name:"${name}", description:"${description}"}){id}}`,
        });

        let req = await fetch(
          "https://intplantoolapi.azurewebsites.net/api/scenario",
          {
            body: body,
            method: "POST",
          }
        );
        let res = await req.text();
        setLastScenarioId(JSON.parse(res).data.createScenario.id);
        const createdId = JSON.parse(res).data.createScenario.id;
        console.log("createdId", createdId);
        //update id in global state
        if (!currentId) {
          let updatedScenarios = allScenarios;
          updatedScenarios.push({
            id: createdId,
            name: name,
            description: description,
          });
          setScenarios(updatedScenarios);

          let updatedEvalAlts = allEvaluatedAlternatives;
          let filteredEvalAlts = allEvaluatedAlternatives.filter((el) => {
            return el.id === null;
          });

          for (const el of filteredEvalAlts) {
            updatedEvalAlts.push({
              score: el.score,
              description: el.description,
              critId: el.critId,
              altId: el.altId,
              deleted: false,
              scenarioId: createdId,
            });
          }
          setEvaluatedAlternatives(updatedEvalAlts);
          /////
          handleClose();
        }
        //reset state
        setName("");
        setDescription("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop>
      <motion.div
        className=" rounded-xl
         bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto w-[40rem]"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-42 p-12 flex flex-col gap-6 min-w-12 ">
          <h2 className="text-2xl text-center text-semibold">
            Create scenario{" "}
          </h2>

          <div className="name flex flex-col text-xl">
            <label className="">Scenario Name</label>
            <input
              type="text"
              className="rounded h-text-input full border-2 border-gray-300 text-black"
              // placeholder="Scenario name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="name flex flex-col text-xl">
            <label>Scenario Description</label>
            <textarea
              rows="5"
              className="rounded border-2 border-gray-300 text-black"
              // placeholder="Scenario description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center h-button"
              onClick={submit}
            >
              SAVE
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-gray-400 dark:bg-wc-grey text-white flex items-center justify-center h-button "
              onClick={handleClose}
            >
              CANCEL
            </motion.button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddScenarioModal;
