import Landing from "./pages/LandingPage";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard.js";
import { AnimatePresence } from "framer-motion";

import { MainProvider } from "./context/MainContext";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter initial={false}>
        <MainProvider>
          <Routes location={location} keys={location.pathname}>
            <Route path="/" element={<Landing />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Routes>
        </MainProvider>
      </AnimatePresence>
    </div>
  );
}

export default App;
