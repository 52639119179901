import React, { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";
import {
  useLastCriteriaId,
  useLastCriteriaIdUpdate,
} from "../../context/CriteriaContext";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddEvaluationTable = ({
  handleClose,
  setCriterias,
  allCriterias,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
  allAlternatives,
  allScenarios,
  setScenarios,
}) => {
  //local state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [metric, setMetric] = useState("");
  const [scoringLevel, setScoringLevel] = useState(null);

  const [scores, setScores] = useState([]);
  const [scoringDesc, setScoringDesc] = useState([]);
  //methods
  const assignScores = (num) => {
    setScoringLevel(num);
    const increment = 1 / parseInt(num - 1);
    let divScore = 0;
    const tempArr = [];
    for (let i = 0; i < num; i++) {
      tempArr.push(parseFloat(divScore.toFixed(2)));
      divScore += increment;
    }
    setScores(tempArr);
  };

  const updateScoringDesc = (index, e) => {
    const tempArr = scoringDesc;
    tempArr[index] = e;
    setScoringDesc(tempArr);
  };

  const lastCriteriaId = useLastCriteriaId();
  const setLastCriteriaId = useLastCriteriaIdUpdate();

  const submit = async (e) => {
    try {
      e.preventDefault();

      const str_score = scores.map((score, index) => {
        return score.toString();
      });

      const scoring_points = str_score.map((el) => {
        return `"${el}"`;
      });
      const scoring_assignment = scoringDesc.map((el) => {
        return `"${el}"`;
      });
      //udpate global cache
      const currentId = lastCriteriaId;
      if (currentId) {
        let updatedCriterias = allCriterias;
        updatedCriterias.push({
          id: lastCriteriaId + 1,
          name: name,
          description: description,
          data_source: dataSource,
          metric: metric,
          scoring_level: parseInt(scoringLevel),
          scoring_points: str_score,
          scoring_assignment: scoringDesc,
          weight: 0,
        });
        setCriterias(updatedCriterias);

        const updatedEvalAlt = allEvaluatedAlternatives;
        for (const alt of allAlternatives) {
          updatedEvalAlt.push({
            altId: alt.id,
            critId: lastCriteriaId + 1,
            score: "",
            description: "",
            sceanrioId: null,
            deleted: false,
          });

          if (allScenarios.length > 0) {
            for (const scenario of allScenarios) {
              updatedEvalAlt.push({
                altId: alt.id,
                critId: lastCriteriaId + 1,
                score: "",
                description: "",
                scenarioId: scenario.id,
                deleted: false,
              });
            }
          }
        }
        setEvaluatedAlternatives(updatedEvalAlt);
        ////////////////////////////////
        handleClose();
      }
      let body = JSON.stringify({
        operation: null,
        query: `mutation createCriteria{createCriteria(data:{name:"${name}", description:"${description}", data_source:"${dataSource}", metric:"${metric}", scoring_level:${parseInt(
          scoringLevel
        )}, scoring_points:[${scoring_points}], scoring_assignment:[${scoring_assignment}]}){id}}`,
      });

      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/criteria",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastCriteriaId(JSON.parse(res).data.createCriteria.id);
      const createdId = JSON.parse(res).data.createCriteria.id;
      console.log("created", createdId);
      //update criteria global state id
      if (!currentId) {
        let updatedCriterias = allCriterias;
        updatedCriterias.push({
          id: createdId,
          name: name,
          description: description,
          data_source: dataSource,
          metric: metric,
          scoring_level: parseInt(scoringLevel),
          scoring_points: str_score,
          scoring_assignment: scoringDesc,
          weight: 0,
        });
        setCriterias(updatedCriterias);

        const updatedEvalAlt = allEvaluatedAlternatives;
        for (const alt of allAlternatives) {
          updatedEvalAlt.push({
            altId: alt.id,
            critId: createdId,
            score: "",
            description: "",
            sceanrioId: null,
            deleted: false,
          });

          if (allScenarios.length > 0) {
            for (const scenario of allScenarios) {
              updatedEvalAlt.push({
                altId: alt.id,
                critId: createdId,
                score: "",
                description: "",
                scenarioId: scenario.id,
                deleted: false,
              });
            }
          }
        }
        setEvaluatedAlternatives(updatedEvalAlt);
        ////////////////////////////////
        handleClose();
      }
      ////reset state
      setName("");
      setDescription("");
      setDataSource("");
      setMetric("");
      setScoringLevel(null);
      setScores([]);
      setScoringDesc([]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop>
      <motion.div
        className="rounded-xl
         bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto w-[50rem]"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-42 p-12 flex flex-col gap-6 min-w-12 ">
          <div className=" rounded w-full p-2 flex gap-2 items-center justify-center ">
            <h2 className="text-2xl font-semibold">Create evaluation </h2>
          </div>
          <div className="flex flex-col text-xl">
            <label>Evaluation criteria name</label>
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
              className="rounded h-text-input text-black border-2 border-gray-300 "
              // placeholder=" Enter critereia name"
            />
          </div>
          <div className="flex flex-col text-xl">
            <label>Evaluation criteria description</label>
            <textarea
              rows="3"
              type="text"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className="rounded h-text-input text-black border-2 border-gray-300"
              // placeholder=" Enter criteria description "
            />
          </div>
          <div className="flex gap-6 items-center text-xl">
            <label> Qualitative or Quantitiave: </label>
            <div className="flex gap-2 items-center">
              <label>Qualitative</label>
              <input
                type="checkbox"
                className="checkbox text-black border-2 border-gray-300"
                checked={metric === "Qualitative"}
                onChange={() => {
                  setMetric("Qualitative");
                }}
              />
              <label>Quantitative</label>
              <input
                type="checkbox"
                className="checkbox text-black border-2 border-gray-300"
                checked={metric === "Quantitative"}
                onChange={() => {
                  setMetric("Quantitative");
                }}
              />
            </div>
          </div>
          <div className="flex flex-col text-xl">
            <label>Data source</label>
            <select
              className="h-text-input text-black rounded border-2 border-gray-300"
              onChange={(e) => {
                setDataSource(e.target.value);
              }}
            >
              <option defaultValue>Select automated or manual</option>
              <option value="AUTOMATED">Automated</option>
              <option value="MANUAL">Manual</option>
            </select>
          </div>
          <div className="flex flex-col text-xl">
            <label>Number of scoring levels</label>
            <select
              className="h-text-input text-black rounded border-2 border-gray-300"
              onChange={(e) => {
                assignScores(e.target.value);
              }}
            >
              <option defaultValue>Select number between 1-5</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div className="flex flex-col gap-2 text-xl">
            <label>Scoring assignments(points)</label>
            {scores.map((score, index) => {
              return (
                <div
                  className="flex gap-2 items-center justify-between"
                  key={index}
                >
                  <label className="basis-1/12">{score}: </label>
                  <textarea
                    rows="3"
                    type="text"
                    onChange={(e) => {
                      updateScoringDesc(index, e.target.value);
                    }}
                    className="rounded h-text-input text-black basis-10/12 w-full border-2 border-gray-300"
                    placeholder=" Describe criteria to get this score "
                  />
                </div>
              );
            })}
            <div className="flex flex-col gap-2">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center h-button"
                onClick={submit}
              >
                SAVE
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="rounded-md p-2 bg-gray-400 dark:bg-wc-grey text-white flex items-center justify-center h-button"
                onClick={submit}
              >
                CANCEL
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddEvaluationTable;
