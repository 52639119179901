import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop.js";

import { OptionNameSort } from "../../utils/OptionSort";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  useLastOptionId,
  useLastOptionIdUpdate,
} from "../../context/OptionContext";

const AddOptionModal = ({
  handleClose,
  setOptions,
  allOptions,
  allAlternatives,
  allAltOptions,
  setAltOptions,
}) => {
  //local state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const lastOptionId = useLastOptionId();
  const setLastOptionId = useLastOptionIdUpdate();

  let projectTypes = useRef([]);
  const [nsChecked, setNsChecked] = useState(false);
  useEffect(() => {
    nsChecked
      ? (projectTypes.current = [...projectTypes.current, "NS"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "NS"
        ));
  }, [nsChecked]);
  const [iesChecked, setIesChecked] = useState(false);
  useEffect(() => {
    iesChecked
      ? (projectTypes.current = [...projectTypes.current, "IES"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "IES"
        ));
  }, [iesChecked]);
  const [nfChecked, setNfChecked] = useState(false);
  useEffect(() => {
    nfChecked
      ? (projectTypes.current = [...projectTypes.current, "NF"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "NF"
        ));
  }, [nfChecked]);
  const [efChecked, setEfChecked] = useState(false);
  useEffect(() => {
    efChecked
      ? (projectTypes.current = [...projectTypes.current, "EF"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "EF"
        ));
  }, [efChecked]);

  const [avgAnnSupply, setAvgAnnSupply] = useState(null);
  const [fCapacity, setFCapacity] = useState(null);

  const [hydroVar, setHydroVar] = useState("");
  const [annVar, setAnnVar] = useState(false);
  const [seaVar, setSeaVar] = useState(false);

  useEffect(() => {
    let hvar = "";
    annVar && (hvar = "ANNUAL");
    seaVar && (hvar = "SEASONAL");
    setHydroVar(hvar);
  }, [annVar, seaVar]);

  const [capitalCost, setCapitalCost] = useState(null);
  const [annualOM, setAnnualOM] = useState(null);
  const [annualWPC, setAnnualWPC] = useState(null);
  const [prc, setPrc] = useState("");
  const [wqc, setWqc] = useState("");
  const [addInfo, setAddInfo] = useState("");

  //methods
  const submit = async (e) => {
    e.preventDefault();
    try {
      //error check to make sure no state is empty
      console.log("option add debug############");
      if (name === "") {
        alert("missing option name");
      } else if (description === "") {
        alert("missing option description");
      } else if (projectTypes.current.length === 0) {
        alert("missing project types");
      } else if (avgAnnSupply === "") {
        alert("missing avg ann supply");
      } else if (fCapacity === "") {
        alert("missing fCapacity");
      } else if (hydroVar === "") {
        alert("missing fCapacity");
      } else if (capitalCost === "") {
        alert("missing fCapacity");
      } else if (annualOM === "") {
        alert("missing annualOM");
      } else if (annualWPC === "") {
        alert("missing annualWPC");
      } else if (prc === "") {
        alert("missing prc");
      } else if (addInfo === "") {
        alert("missing addInfo");
      } else {
        //update global state
        ////////////
        //lastOption id is last added option from api to keep track of id cache
        const currentId = lastOptionId;
        if (currentId) {
          let updatedOptions = allOptions;
          updatedOptions.push({
            id: lastOptionId + 1,
            name: name,
            description: description,
            projectType: projectTypes.current,
            avgAnnSupply: parseInt(avgAnnSupply),
            fCapacity: parseInt(fCapacity),
            hydroVar: hydroVar,
            capitalCost: parseInt(capitalCost),
            annualOM: parseInt(annualOM),
            annualWPC: parseInt(annualWPC),
            prc: prc,
            wqc: wqc,
            addInfo: addInfo,
            creatorId: parseInt(localStorage.getItem("userId")),
          });

          const nameSort = OptionNameSort(updatedOptions);
          console.log("temp", nameSort);
          setOptions(nameSort);
          if (allAlternatives.length > 0) {
            const updatedAltOpts = allAltOptions;
            for (const alt of allAlternatives) {
              const newAltOpts = {
                altId: alt.id,
                optId: lastOptionId + 1,
                checked: false,
              };
              updatedAltOpts.push(newAltOpts);
            }
            setAltOptions(updatedAltOpts);
          }
          ///////////////
          handleClose();
        }
        //////save option to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation createOption{ createOption(data:{name:"${name}", description:"${description}", projectType:[${
            projectTypes.current
          }], avgAnnSupply:${parseInt(avgAnnSupply)}, fCapacity:${parseInt(
            fCapacity
          )},hydroVar:${hydroVar},capitalCost:${parseInt(
            capitalCost
          )}, annualOM:${parseInt(annualOM)}, annualWPC:${parseInt(
            annualWPC
          )}, prc:"${prc}", wqc:"${wqc}", addInfo:"${addInfo}", creatorId:${parseInt(
            localStorage.getItem("userId")
          )}}){id}}`,
        });

        let req = await fetch(
          "https://intplantoolapi.azurewebsites.net/api/option",
          {
            body: body,
            method: "POST",
          }
        );
        let res = await req.text();
        setLastOptionId(JSON.parse(res).data.createOption.id);
        const createdId = JSON.parse(res).data.createOption.id;
        console.log("creatorOption", createdId);

        if (!currentId) {
          ///set option id for recently added option to global state
          let updatedOptions = allOptions;
          updatedOptions.push({
            id: createdId,
            name: name,
            description: description,
            projectType: projectTypes.current,
            avgAnnSupply: parseInt(avgAnnSupply),
            fCapacity: parseInt(fCapacity),
            hydroVar: hydroVar,
            capitalCost: parseInt(capitalCost),
            annualOM: parseInt(annualOM),
            annualWPC: parseInt(annualWPC),
            prc: prc,
            wqc: wqc,
            addInfo: addInfo,
            creatorId: parseInt(localStorage.getItem("userId")),
          });
          //get first word in each name (includes nums + strings)
          const sortList = updatedOptions.map((el) => {
            return el.name.split(" ")[0];
          });
          //sorts alpha numeric values 1a,1b,2a,2b/ https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
          var collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });
          sortList.sort(collator.compare);

          let tempList = updatedOptions.sort((a, b) => {
            return (
              sortList.indexOf(a.name.split(" ")[0]) -
              sortList.indexOf(b.name.split(" ")[0])
            );
          });
          //////
          console.log("tempList", tempList);
          setOptions(tempList);
          if (allAlternatives.length > 0) {
            const updatedAltOpts = allAltOptions;
            for (const alt of allAlternatives) {
              const newAltOpts = {
                altId: alt.id,
                optId: createdId,
                checked: false,
              };
              updatedAltOpts.push(newAltOpts);
            }
            setAltOptions(updatedAltOpts);
          }
          ///////////////
          handleClose();
        }

        ////////////////
        //reset state
        setName("");
        setDescription("");
        projectTypes.current = [];
        setNsChecked(false);
        setIesChecked(false);
        setNfChecked(false);
        setEfChecked(false);
        setAvgAnnSupply(false);
        setFCapacity(false);
        setHydroVar("");
        setAnnVar(false);
        setSeaVar(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //animations
  const dropIn = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: { y: "100vh", opacity: 0 },
  };
  return (
    <Backdrop>
      <motion.div
        className="w-6/12 max-h-[700px] overflow-auto bg-gray-100 text-wc-green dark:bg-wc-gradient bg-cover dark:text-white shadow-md rounded-xl font-roboto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="w-full p-12 flex flex-col gap-8 line-clamp-7">
          <div className="flex items-center justify-center ">
            <h2 className="text-2xl font-semibold">Create new option</h2>
          </div>
          <div className="option-info flex flex-col gap-4 mb-4">
            <div className="option-name flex flex-col gap-2 text-xl">
              <label className=" text-xl">Option Name (required)</label>
              <input
                type="text"
                className="option-name-input rounded h-text-input text-black border-2 border-gray-300 pl-2"
                // placeholder=" Enter option name "
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="option-description flex flex-col gap-2 text-xl">
              <label className=" text-xl">Description (required)</label>
              {/* <textarea
                rows="3"
                className="option-name-input rounded text-black border-2 border-gray-300 pl-2"
                // placeholder=" Enter option description"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              /> */}
              <div className="bg-white">
                <ReactQuill
                  theme="snow"
                  className="w-full text-black rounded h-[8rem] mb-12 bg-white"
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </div>
            <div className="project-type flex items-center gap-6 text-xl">
              <span>
                Project Type<p>(Check all that apply)</p>
              </span>
              <input
                id="new-supply"
                type="checkbox"
                className="w-6 h-6  border-gray-300"
                checked={nsChecked}
                value="NS"
                onChange={(e) => {
                  setNsChecked(!nsChecked);
                }}
              />
              <label htmlFor="new-supply">New Supply</label>
              <input
                id="increase-supply"
                type="checkbox"
                className="w-6 h-6 border-2  border-gray-300"
                checked={iesChecked}
                value="IES"
                onChange={(e) => {
                  setIesChecked(!iesChecked);
                }}
              />
              <label htmlFor="increase-supply">Increase Existing Supply</label>
              <input
                id="new-facility"
                type="checkbox"
                className="w-6 h-6  border-gray-300"
                checked={nfChecked}
                value="NF"
                onChange={(e) => {
                  setNfChecked(!nfChecked);
                }}
              />
              <label htmlFor="new-facility">New Facility</label>
              <input
                id="existing-facility"
                type="checkbox"
                className="w-6 h-6  border-gray-300"
                checked={efChecked}
                value="EF"
                onChange={(e) => {
                  setEfChecked(!efChecked);
                }}
              />
              <label htmlFor="existing-facility">Existing Facility</label>
            </div>
          </div>
          <div className="supply-volume flex flex-col border-gray-300 dark:border-white border-2 p-6 rounded-xl shadow gap-6 text-xl">
            <h3 className="font-semibold underline">
              Supply Volume and Facility Capacity
            </h3>
            <div className="flex gap-2">
              <div className="avg-annual-supply flex flex-col gap-2 rounded basis-4/12">
                <label htmlFor="aas-input">Average Annual Supply (AFY)</label>
                <input
                  id="aas-input"
                  type="text"
                  // placeholder=" Enter number"
                  className="h-text-input rounded text-black border-gray-300 border-2 pl-2"
                  onChange={(e) => {
                    setAvgAnnSupply(e.target.value);
                  }}
                />
              </div>
              <div className="facility-capacity flex flex-col gap-2 basis-4/12">
                <label htmlFor="fc-input">Facility Capacity (MGD)</label>
                <input
                  id="fc-input"
                  type="text"
                  // placeholder=" Enter number"
                  className="h-text-input rounded text-black border-gray-300 border-2 pl-2"
                  onChange={(e) => {
                    setFCapacity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="hydrologic-variability flex gap-6 items-center ">
              <label htmlFor="hv-input">Hydrologic Variability : </label>
              <input
                type="checkbox"
                id="hv-input"
                className="w-6 h-6  border-gray-300"
                checked={annVar}
                onChange={(e) => {
                  setAnnVar(!annVar);
                  seaVar === true && setSeaVar(false);
                }}
              />
              <label>Annual Variability</label>
              <input
                type="checkbox"
                id="hv-input"
                className="w-6 h-6  border-gray-300"
                checked={seaVar}
                onChange={(e) => {
                  setSeaVar(!seaVar);
                  annVar === true && setAnnVar(false);
                }}
              />
              <label>Seasonal Variability</label>
            </div>
          </div>
          <div className="costs flex flex-col border-gray-300 dark:border-white border-2  dark:text-white p-6 gap-4 text-xl rounded-xl shadow">
            <h3 className="font-semibold underline">Costs</h3>
            <div className="cost-inputs flex gap-2">
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">Capital Cost(Total$)</label>
                <input
                  type="text"
                  // placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-gray-300 border-2 pl-2"
                  onChange={(e) => {
                    setCapitalCost(e.target.value);
                  }}
                />
              </div>
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">Annual O&M($/yr)</label>
                <input
                  type="text"
                  // placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-gray-300 border-2 pl-2"
                  onChange={(e) => {
                    setAnnualOM(e.target.value);
                  }}
                />
              </div>
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">
                  Annual Water Purchase Cost($/AFY)
                </label>
                <input
                  type="text"
                  // placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-gray-300 border-2 pl-2"
                  onChange={(e) => {
                    setAnnualWPC(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col border-gray-300 border-2 dark:border-white gap-4 p-4 text-xl rounded-xl shadow">
            <h3 className="font-semibold underline">Other Considerations</h3>
            <div className="flex flex-col justify-between gap-2">
              <label htmlFor="prc-input basis-2/12">
                Permitting and Regulatory Considerations
              </label>
              <textarea
                id="prc-input"
                className="basis-10/12 rounded text-black border-gray-300 border-2 pl-2"
                rows="3"
                // placeholder=" Enter text"
                onChange={(e) => {
                  setPrc(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="prc-input basis-2/12">
                Water Quality Considerations
              </label>
              <textarea
                id="prc-input"
                className="w-full basis-10/12 rounded text-black border-gray-300 border-2 pl-2"
                rows="3"
                // placeholder=" Enter text"
                onChange={(e) => {
                  setWqc(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="prc-input basis-2/12">
                Additional Information
              </label>
              <textarea
                id="prc-input"
                className="w-full basis-10/12 rounded text-black border-gray-300 border-2 pl-2"
                rows="3"
                // placeholder=" Enter text"
                onChange={(e) => {
                  setAddInfo(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button  mx-auto w-[40rem]"
              onClick={submit}
            >
              SAVE
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="rounded-md p-2 bg-gray-400 dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button  mx-auto w-[40rem]"
              onClick={handleClose}
            >
              CANCEL
            </motion.button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddOptionModal;
