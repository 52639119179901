import React, { useState, useRef } from "react";
import SidebarFooter from "./SidebarFooter";
import { BsClipboardData } from "react-icons/bs";
import { CgOptions } from "react-icons/cg";
import { GrDirections } from "react-icons/gr";
import { FaBookReader } from "react-icons/fa";
import { BsSunFill, BsMoonStars } from "react-icons/bs";
import { BsBatteryCharging } from "react-icons/bs";

import useDarkMode from "../../hook/useDarkMode";

import { AiOutlineLogout } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { GrUserAdmin } from "react-icons/gr";

import { AnimatePresence } from "framer-motion";

const Sidebar = ({
  toggleAdmin,
  toggleProfile,
  toggleOptions,
  toggleSidebar,
  showSidebar,
  toggleAlternatives,
  toggleEvaluation,
  toggleScenario,
  toggleAltSummary,
  logout,
}) => {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  const [colorTheme, setTheme] = useDarkMode();

  const [menuIndex, setMenuIndex] = useState(Array(8).fill(false));
  const menuToggle = (index) => {
    const tempArr = Array(8).fill(false);
    tempArr[index] = true;
    setMenuIndex(tempArr);
  };

  const toolTipWidth = useRef(null);

  const [optionHover, setOptionHover] = useState(false);
  const optionMouseOver = () => {
    setOptionHover(true);
  };
  const optionMouseOut = () => {
    setOptionHover(false);
  };

  const [altHover, setAltHover] = useState(false);
  const altMouseOver = () => {
    setAltHover(true);
  };
  const altMouseOut = () => {
    setAltHover(false);
  };

  const [evalHover, setEvalHover] = useState(false);
  const evalMouseOver = () => {
    setEvalHover(true);
  };
  const evalMouseOut = () => {
    setEvalHover(false);
  };

  const [scenarioHover, setScenarioHover] = useState(false);
  const scenarioMouseOver = () => {
    setScenarioHover(true);
  };
  const scenarioMouseOut = () => {
    setScenarioHover(false);
  };

  const [altSummaryHover, setAltSummaryHover] = useState(false);
  const altSummaryMouseOver = () => {
    setAltSummaryHover(true);
  };
  const altSummaryMouseOut = () => {
    setAltSummaryHover(false);
  };

  const [profileHover, setProfileHover] = useState(false);
  const profileMouseOver = () => {
    setProfileHover(true);
  };
  const profileMouseOut = () => {
    setProfileHover(false);
  };

  const [adminHover, setAdminHover] = useState(false);
  const adminMouseOver = () => {
    setAdminHover(true);
  };
  const adminMouseOut = () => {
    setAdminHover(false);
  };

  const [logoutHover, setLogoutHover] = useState(false);
  const logoutMouseOver = () => {
    setLogoutHover(true);
  };
  const logoutMouseOut = () => {
    setLogoutHover(false);
  };

  return (
    <div
      className={`fixed left-0 min-h- w-80 bg-wc bg-cover text-white inset-y-0 transform transition duration-200 ease-in-out font-roboto z-20 pt-10 mt-10 ${
        showSidebar ? "" : "-translate-x-3/4 "
      }`}
    >
      <div className="fixed top-0 left-0 flex flex-col min-h-screen w-80 bg-white  text-black dark:bg-black transition duration-200 dark:text-white opacity-80 font-bold">
        <div
          className={`flex items-center p-4 ${
            showSidebar ? "justify-center" : "justify-end mr-1"
          }`}
        >
          <div
            className={`h-[7rem] w-full bg-client-logo bg-contain bg-no-repeat  ${
              !showSidebar && "hidden"
            }`}
          />
          {/* <div
            className="cursor-pointer rounded-3xl h-10 w-10 bg-yellow-500 dark:bg-blue-500 transistion duration-200 flex items-center justify-center"
            onClick={() => {
              setTheme(colorTheme);
            }}
          >
            {colorTheme === "light" ? (
              <BsMoonStars size="18" />
            ) : (
              <BsSunFill size="18" />
            )}
          </div> */}
        </div>
        <hr className="opacity-40" />
        {/* <div
          className={`profile items-center flex text-white py-5 ml-5 ${
            showSidebar ? "" : "justify-end "
          }`}
        >
          <div className="profile-image w-16 mr-[.5em]">
            <img src={require("../../assets/icon.png")} alt="profile icon" />
          </div>
          <div
            className={`profile-status flex flex-col ml-5 text-xs gap-1 text-black dark:text-white ${
              showSidebar ? "visible" : "hidden"
            }`}
          >
            <span>{`${firstName} ${lastName}`}</span>

            <span>{localStorage.getItem("role")}</span>
            <span className="flex items-center">
              <div className="bg-lime-400 rounded-full w-2 h-2 mr-2"></div>
              online
            </span>
          </div>
          <hr className="opacity-40" />
        </div> */}
        <hr className="opacity-40" />
        <hr className="opacity-40" />
        <AnimatePresence exitBeforeEnter initial={false}>
          <div className="py-5 ml-4  text-xl ">
            <h2 className={`${showSidebar ? "" : "hidden"}`}>Planning Tool</h2>
            <ul>
              <li
                className={`flex items-center mt-4 ${
                  !menuIndex[0] && "hover:bg-wc-grey"
                } cursor-pointer rounded ${showSidebar && "p-2 mr-4"} ${
                  menuIndex[0] && "bg-wc-green"
                }`}
                onClick={() => {
                  menuToggle(0);
                  toggleOptions();
                }}
                onMouseOver={optionMouseOver}
                onMouseOut={optionMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto  relative"
                  }`}
                >
                  <CgOptions size={`${showSidebar ? "28" : "32"}`} />
                  {/* 
                  {!showSidebar && optionHover && (
                    <motion.div
                      className={`w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-110px] text-white rounded p-4 text flex justify-center items-center`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Options
                    </motion.div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Options
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>

              <li
                className={`flex items-center mt-1 ${
                  !menuIndex[1] && "hover:bg-wc-grey"
                } cursor-pointer rounded ${showSidebar && "p-2 mr-4"} ${
                  menuIndex[1] && "bg-wc-green"
                }`}
                onClick={() => {
                  menuToggle(1);
                  toggleAlternatives();
                }}
                onMouseOver={altMouseOver}
                onMouseOut={altMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                  }`}
                >
                  <BsBatteryCharging size={`${showSidebar ? "28" : "32"}`} />
                  {/* {!showSidebar && altHover && (
                    <motion.div
                      className="w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-150px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives
                    </motion.div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Alternatives
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>
              <li
                className={`flex items-center mt-1 ${
                  !menuIndex[2] && "hover:bg-wc-grey"
                } cursor-pointer rounded ${showSidebar && "p-2 mr-4"} ${
                  menuIndex[2] && "bg-wc-green"
                }`}
                onClick={() => {
                  menuToggle(2);
                  toggleEvaluation();
                }}
                onMouseOver={evalMouseOver}
                onMouseOut={evalMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                  }`}
                >
                  <BsClipboardData size={`${showSidebar ? "28" : "32"}`} />
                  {/* {!showSidebar && evalHover && (
                    <motion.div
                      className="w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-145px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Evaluations
                    </motion.div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Evaluation
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>
              <li
                className={`flex items-center mt-1 ${
                  !menuIndex[3] && "hover:bg-wc-grey"
                } cursor-pointer rounded ${showSidebar && "p-2 mr-4"} ${
                  menuIndex[3] && "bg-wc-green"
                }`}
                onClick={() => {
                  menuToggle(3);
                  toggleScenario();
                }}
                onMouseOver={scenarioMouseOver}
                onMouseOut={scenarioMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                  }`}
                >
                  <GrDirections size={`${showSidebar ? "28" : "32"}`} />{" "}
                  {/* {!showSidebar && scenarioHover && (
                    <motion.div
                      className="w-fit h-10 absolute bg-wc-green top-0 right-[-132px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Scenarios
                    </motion.div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Scenarios
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>
              <li
                className={`flex items-center mt-1 ${
                  !menuIndex[4] && "hover:bg-wc-grey"
                } cursor-pointer rounded ${showSidebar && "p-2 mr-4"} ${
                  menuIndex[4] && "bg-wc-green"
                }`}
                onClick={() => {
                  menuToggle(4);
                  toggleAltSummary();
                }}
                onMouseOver={altSummaryMouseOver}
                onMouseOut={altSummaryMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                  }`}
                >
                  <FaBookReader size={`${showSidebar ? "28" : "32"}`} />
                  {/* {!showSidebar && altSummaryHover && (
                    <motion.div
                      className="w-fit h-18 absolute bg-wc-green top-0 bottom-0 right-[-148px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives Summary
                    </motion.div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Alternatives Summary
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>
            </ul>
          </div>
        </AnimatePresence>
        <hr className="w-full text black mb-6" />
        <AnimatePresence exitBeforeEnter initial={false}>
          <div className=" pl-5 text-xl">
            <h2 className={`${showSidebar ? "" : "hidden"}`}>
              User Management
            </h2>
            <ul>
              {localStorage.getItem("role") === "ADMIN" && (
                <li
                  className={`flex items-center mt-1  cursor-pointer rounded hover:bg-wc-grey  ${
                    showSidebar && "p-2 mr-4"
                  } ${menuIndex[6] && "bg-wc-green"}`}
                  onClick={() => {
                    menuToggle(6);
                    toggleAdmin();
                  }}
                  onMouseOver={adminMouseOver}
                  onMouseOut={adminMouseOut}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                        : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <GrUserAdmin size={`${showSidebar ? "28" : "32"}`} />
                    {/* {!showSidebar && adminHover && (
                      <div
                        className="w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-100px] text-white rounded p-4 text flex justify-center items-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        admin
                      </div>
                    )} */}
                  </div>
                  <span
                    className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                  >
                    Admin
                  </span>
                  <span className={`${showSidebar ? "" : "hidden"}`}>
                    <MdKeyboardArrowRight size="28" />
                  </span>
                </li>
              )}
              <li
                className={`flex items-center mt-1 hover:bg-wc-grey cursor-pointer rounded ${
                  showSidebar && "p-2 mr-4"
                }`}
                onClick={logout}
                onMouseOver={logoutMouseOver}
                onMouseOut={logoutMouseOut}
              >
                <div
                  className={`"" ${
                    showSidebar
                      ? "w-8 h-8 bg-slate-400 mr-2 text-black flex items-center justify-center rounded "
                      : "w-16 h-16 bg-slate-400 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                  }`}
                >
                  <AiOutlineLogout size={`${showSidebar ? "28" : "32"}`} />
                  {/* {!showSidebar && logoutHover && (
                    <div
                      className="w-fit h-10 absolute bg-wc-green bottom-0 right-[-200px]  text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      logout
                    </div>
                  )} */}
                </div>
                <span
                  className={`${showSidebar ? "ml-2 basis-8/12" : "hidden"}`}
                >
                  Logout
                </span>
                <span className={`${showSidebar ? "" : "hidden"}`}>
                  <MdKeyboardArrowRight size="28" />
                </span>
              </li>
            </ul>
          </div>
        </AnimatePresence>
        <SidebarFooter toggle={toggleSidebar} showSidebar={showSidebar} />
      </div>
    </div>
  );
};

export default Sidebar;
