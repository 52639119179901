import React from "react";
import { AiFillBell, AiFillMail, AiOutlinePoweroff } from "react-icons/ai";
import { BsGearFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

const SidebarFooter = ({ toggle, showSidebar }) => {
  return (
    <div className="min-h-content h-10 min-w-full bg-wc-green absolute bottom-0 flex items-center justify-end mb-10">
      {/* <div className="basis-3/12 cursor-pointer flex justify-center hover:text-white p-2 dark:hover:text-blue-300">
        <AiFillBell size="24" />
        <div className="w-4 h-4 bg-yellow-500 text-xs  text-center align-middle flex justify-center items-center rounded">
          <span className="text-black ">3</span>
        </div>
      </div>
      <div className="basis-3/12 cursor-pointer flex justify-center hover:text-white p-2 dark:hover:text-blue-300">
        <AiFillMail size="24" />
        <div className="w-4 h-4 bg-green-500 text-xs  text-center align-middle flex justify-center items-center rounded">
          <span className="text-black ">7</span>
        </div>
      </div>
      <div className="basis-3/12 cursor-pointer flex justify-center hover:text-white p-2 dark:hover:text-blue-300">
        <BsGearFill size="18" />
      </div> */}
      <div className="basis-3/12 cursor-pointer flex justify-center text-white hover:text-green-200 p-2 dark:hover:text-blue-300">
        {showSidebar ? (
          <AiOutlineArrowLeft size="22" onClick={toggle} />
        ) : (
          <AiOutlineArrowRight size="22" onClick={toggle} />
        )}
      </div>
    </div>
  );
};

export default SidebarFooter;
