import React, { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";
//animations
const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const UpdateScenarioModal = ({
  handleClose,
  scenario,
  setScenarios,
  allScenarios,
}) => {
  const [name, setName] = useState(scenario[0].name);
  const [description, setDescription] = useState(scenario[0].description);

  const submit = async (e) => {
    try {
      ////update scenario global state
      let updatedScenarios = allScenarios;
      for (const [index, sc] of updatedScenarios.entries()) {
        if (sc.id === scenario[0].id) {
          updatedScenarios[index] = {
            id: scenario[0].id,
            name: name,
            description: description,
          };
        }
      }
      setScenarios(updatedScenarios);
      //////
      handleClose();
      ////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation updateScenario{ updateScenario(data:{id:${scenario[0].id}, name:"${name}", description:"${description}"}){id}}`,
      });
      await fetch("https://intplantoolapi.azurewebsites.net/api/scenario", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="rounded-xl bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto overflow-auto w-fit p-12 flex flex-col gap-6 "
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-42  flex flex-col gap-6 min-w-12 ">
          <h2 className="text-2xl ">Update scenario </h2>
        </div>
        <div className="name flex flex-col">
          <label className="text-sm">Scenario Name</label>
          <input
            type="text"
            className="rounded h-text-input w-64 border-2 border-gray-300 text-black"
            placeholder="Scenario name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="name flex flex-col">
          <label className="text-sm">Scenario Description</label>
          <textarea
            rows="5"
            className="rounded border-2 border-gray-300 text-black"
            placeholder="Scenario description"
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button text-xl"
          onClick={submit}
        >
          Save
        </motion.button>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateScenarioModal;
