import React, { createContext, useState, useContext } from "react";
import Alternative from "../components/Alternative/Alternative";

const AlternativeContext = createContext();
const AlternativeUpdateContext = createContext();

const AlternativeOptionContext = createContext();
const AlternativeOptionUpdateContext = createContext();

const LastAlternativeIdContext = createContext();
const LastAlternativeIdUpdateContext = createContext();

const TotalAvgAnnSupplyContext = createContext();
const TotalAvgAnnSupplyUpdateContext = createContext();

const TotalOmContext = createContext();
const TotalOmUpdateContext = createContext();

const TotalWaterPurchaseContext = createContext();
const TotalWaterPurchaseUpdateContext = createContext();

const TotalWpcContext = createContext();
const TotalWpcUpdateContext = createContext();

export const useTotalWpc = () => {
  return useContext(TotalWpcContext);
};
export const useTotalWpcUpdate = () => {
  return useContext(TotalWpcUpdateContext);
};

export const useTotalWaterPurchase = () => {
  return useContext(TotalWaterPurchaseContext);
};
export const useTotalWaterPurchaseUpdate = () => {
  return useContext(TotalWaterPurchaseUpdateContext);
};
export const useTotalOm = () => {
  return useContext(TotalOmContext);
};
export const useTotalOmUpdate = () => {
  return useContext(TotalOmUpdateContext);
};

export const useTotalAvgAnnSupply = () => {
  return useContext(TotalAvgAnnSupplyContext);
};

export const useTotalAvgAnnSupplyUpdate = () => {
  return useContext(TotalAvgAnnSupplyUpdateContext);
};

export const useAlternative = () => {
  return useContext(AlternativeContext);
};

export const useAlternativeUpdate = () => {
  return useContext(AlternativeUpdateContext);
};

export const useLastAlternativeId = () => {
  return useContext(LastAlternativeIdContext);
};

export const useLastAlternativeIdUpdate = () => {
  return useContext(LastAlternativeIdUpdateContext);
};

export const useAlternativeOption = () => {
  return useContext(AlternativeOptionContext);
};

export const useAlternativeOptionUpdate = () => {
  return useContext(AlternativeOptionUpdateContext);
};

export const AlternativeProvider = ({ children }) => {
  const [alternative, setAlternative] = useState(null);
  const updateAlternative = (alt) => {
    setAlternative(alt);
  };

  const [lastId, setLastId] = useState(null);
  const updateLastId = (id) => {
    setLastId(id);
  };

  const [altOption, setAltOption] = useState(null);
  const updateAltOption = (altOpt) => {
    setAltOption(altOpt);
  };

  //alternative totals dictionaries
  const [totalAvgAnnSupply, setTotalAvgAnnSupply] = useState({});
  const updateTotalAvgAnnSupply = (options) => {
    const altAvgAnnSupply = {};
    alternative.map((alt) => {
      let counter = 0;
      for (const opt of altOption) {
        if (opt.checked === true && opt.altId === alt.id) {
          counter += options.filter((option) => {
            return option.id === opt.optId;
          })[0].avgAnnSupply;
        }
      }
      altAvgAnnSupply[alt.id] = counter;
      setTotalAvgAnnSupply(altAvgAnnSupply);
      return alt;
    });
  };

  const [totalOm, setTotalOm] = useState(null);
  const updateTotalOm = (options) => {
    const altTotalOm = {};
    alternative.map((alt) => {
      let counter = 0;
      for (const opt of altOption) {
        if (opt.checked === true && opt.altId === alt.id) {
          counter += options.filter((option) => {
            return option.id === opt.optId;
          })[0].annualOM;
        }
      }
      altTotalOm[alt.id] = counter;
      setTotalOm(altTotalOm);
      return alt;
    });
  };

  const [totalWaterPurchase, setTotalWaterPurchase] = useState(null);
  const updateTotalWaterPurchase = (options) => {
    const altTotalWp = {};
    alternative.map((alt) => {
      let counter = 0;
      for (const opt of altOption) {
        if (opt.checked === true && opt.altId === alt.id) {
          counter += options.filter((option) => {
            return option.id === opt.optId;
          })[0].annualWPC;
        }
      }
      altTotalWp[alt.id] = counter;
      // console.log("checkTotal", altTotalWp);
      setTotalWaterPurchase(altTotalWp);
      return alt;
    });
  };

  const [totalWpc, setTotalWpc] = useState(null);
  const updateTotalWpc = (options) => {
    const altTotalWp = {};
    alternative.map((alt) => {
      let counter = 0;
      for (const opt of altOption) {
        if (opt.checked === true && opt.altId === alt.id) {
          counter +=
            options.filter((option) => {
              return option.id === opt.optId;
            })[0].annualWPC *
            options.filter((option) => {
              return option.id === opt.optId;
            })[0].avgAnnSupply;
        }
      }
      altTotalWp[alt.id] = counter;
      console.log("checkTotal", altTotalWp);

      setTotalWpc(altTotalWp);
      return alt;
    });
  };

  return (
    <>
      <AlternativeContext.Provider value={alternative}>
        <AlternativeUpdateContext.Provider value={updateAlternative}>
          <LastAlternativeIdContext.Provider value={lastId}>
            <LastAlternativeIdUpdateContext.Provider value={updateLastId}>
              <AlternativeOptionContext.Provider value={altOption}>
                <AlternativeOptionUpdateContext.Provider
                  value={updateAltOption}
                >
                  <TotalAvgAnnSupplyUpdateContext.Provider
                    value={updateTotalAvgAnnSupply}
                  >
                    <TotalAvgAnnSupplyContext.Provider
                      value={totalAvgAnnSupply}
                    >
                      <TotalOmContext.Provider value={totalOm}>
                        <TotalOmUpdateContext.Provider value={updateTotalOm}>
                          <TotalWaterPurchaseUpdateContext.Provider
                            value={updateTotalWaterPurchase}
                          >
                            <TotalWaterPurchaseContext.Provider
                              value={totalWaterPurchase}
                            >
                              <TotalWpcContext.Provider value={totalWpc}>
                                <TotalWpcUpdateContext.Provider
                                  value={updateTotalWpc}
                                >
                                  {children}
                                </TotalWpcUpdateContext.Provider>
                              </TotalWpcContext.Provider>
                            </TotalWaterPurchaseContext.Provider>
                          </TotalWaterPurchaseUpdateContext.Provider>
                        </TotalOmUpdateContext.Provider>
                      </TotalOmContext.Provider>
                    </TotalAvgAnnSupplyContext.Provider>
                  </TotalAvgAnnSupplyUpdateContext.Provider>
                </AlternativeOptionUpdateContext.Provider>
              </AlternativeOptionContext.Provider>
            </LastAlternativeIdUpdateContext.Provider>
          </LastAlternativeIdContext.Provider>
        </AlternativeUpdateContext.Provider>
      </AlternativeContext.Provider>
    </>
  );
};
