import React, { createContext, useState, useContext } from "react";

const CriteriaContext = createContext();
const CriteriaUpdateContext = createContext();
const LastCriteriaIdContext = createContext();
const LastCriteriaIdUpdateContext = createContext();

export const useCriteria = () => {
  return useContext(CriteriaContext);
};

export const useCriteriaUpdate = () => {
  return useContext(CriteriaUpdateContext);
};

export const useLastCriteriaId = () => {
  return useContext(LastCriteriaIdContext);
};

export const useLastCriteriaIdUpdate = () => {
  return useContext(LastCriteriaIdUpdateContext);
};

export const CriteriaProvider = ({ children }) => {
  const [criteria, setCriteria] = useState(null);
  const updateCriteria = (criteria) => {
    setCriteria(criteria);
  };
  const [lastId, setLastId] = useState(null);
  const updateLastId = (id) => {
    setLastId(id);
  };

  return (
    <>
      <CriteriaContext.Provider value={criteria}>
        <CriteriaUpdateContext.Provider value={updateCriteria}>
          <LastCriteriaIdContext.Provider value={lastId}>
            <LastCriteriaIdUpdateContext.Provider value={updateLastId}>
              {children}
            </LastCriteriaIdUpdateContext.Provider>
          </LastCriteriaIdContext.Provider>
        </CriteriaUpdateContext.Provider>
      </CriteriaContext.Provider>
    </>
  );
};
