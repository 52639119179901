import React, { createContext, useState, useContext } from "react";

const ScenarioContext = createContext();
const ScenarioUpdateContext = createContext();

const LastScenarioIdContext = createContext();
const LastScenarioIdUpdateContext = createContext();

export const useScenario = () => {
  return useContext(ScenarioContext);
};

export const useScenarioUpdate = () => {
  return useContext(ScenarioUpdateContext);
};
export const useLastScenarioId = () => {
  return useContext(LastScenarioIdContext);
};

export const useLastScenarioIdUpdate = () => {
  return useContext(LastScenarioIdUpdateContext);
};

export const ScenarioProvider = ({ children }) => {
  const [scenario, setScenario] = useState(null);
  const updateScenario = (scenario) => {
    setScenario(scenario);
  };

  const [lastId, setLastId] = useState(null);
  const updateLastId = (id) => {
    setLastId(id);
  };

  return (
    <>
      <ScenarioContext.Provider value={scenario}>
        <ScenarioUpdateContext.Provider value={updateScenario}>
          <LastScenarioIdContext.Provider value={lastId}>
            <LastScenarioIdUpdateContext.Provider value={updateLastId}>
              {children}
            </LastScenarioIdUpdateContext.Provider>
          </LastScenarioIdContext.Provider>
        </ScenarioUpdateContext.Provider>
      </ScenarioContext.Provider>
    </>
  );
};
