import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useUserLoad } from "../context/UserContext";

import { BsSunFill, BsMoonStars } from "react-icons/bs";

import {
  useUser,
  useUserUpdate,
  useLastUserIdUpdate,
} from "../context/UserContext";
import {
  useOption,
  useOptionUpdate,
  useLastOptionIdUpdate,
} from "../context/OptionContext";
import {
  useCriteria,
  useCriteriaUpdate,
  useLastCriteriaIdUpdate,
} from "../context/CriteriaContext";
import {
  useScenario,
  useScenarioUpdate,
  useLastScenarioIdUpdate,
} from "../context/ScenarioContext";
import {
  useAlternative,
  useAlternativeUpdate,
  useLastAlternativeIdUpdate,
  useAlternativeOption,
  useAlternativeOptionUpdate,
  useTotalAvgAnnSupply,
  useTotalAvgAnnSupplyUpdate,
} from "../context/AlternativeContext";
import {
  useEvaluatedAlternative,
  useEvaluatedAlternativeUpdate,
} from "../context/EvaluatedAlternativeContext";

import Sidebar from "../components/Sidebar/Sidebar";
import AdminTable from "../components/User/AdminTable";
import AddUserModal from "../components/User/AddUserModal";
import ProfileSettings from "../components/User/ProfileSettings";
import OptionsTable from "../components/Option/OptionsTable";
import AddOptionModal from "../components/Option/AddOptionModal";
import AltTable from "../components/Alternative/AlternativeTable";
import AddAlternativeModal from "../components/Alternative/AddAlternativeModal";
import EvalTable from "../components/Evaluation/EvalutationTable";
import AddEvalModal from "../components/Evaluation/AddEvaluationModal";
import UpdateAlternativeModal from "../components/Alternative/UpdateAlternativeModal";
import AlternativeSummaryTable from "../components/Alternative/AlternativeSummaryTable";
import ScenarioTable from "../components/Scenario/ScenarioTable";
import AddScenarioModal from "../components/Scenario/AddScenarioModal";

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineSettings } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

import useDarkMode from "../hook/useDarkMode";

//animations
const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "100vh", opacity: 0 },
};
const Dashboard = () => {
  const [colorTheme, setTheme] = useDarkMode();

  const load = useUserLoad();
  const navigate = useNavigate();

  const allUsers = useUser();
  const setUsers = useUserUpdate();
  const setLastUserId = useLastUserIdUpdate();
  const getUsers = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{allUsers{email, id,firstName,lastName,phone,role,deleted}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/user",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("users", JSON.parse(res).data.allUsers);
      setUsers(JSON.parse(res).data.allUsers);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastUserId = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{lastUserId}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/user",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastUserId(JSON.parse(res).data.lastUserId);
    } catch (err) {
      console.log(err);
    }
  };

  // const allOptions = useQuery(ALL_OPTIONS);
  const allOptions = useOption();
  const setOptions = useOptionUpdate();
  const setLastOptionId = useLastOptionIdUpdate();
  const getOptions = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query:
          "{allOptions{creator{id,firstName,lastName},id,name,description,projectType,avgAnnSupply,fCapacity,hydroVar,capitalCost,annualOM,annualWPC,prc,wqc,addInfo,createdAt,updatedAt}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/option",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("options", JSON.parse(res).data.allOptions);
      setOptions(JSON.parse(res).data.allOptions);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastOptionId = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{lastOptionId}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/option",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastOptionId(JSON.parse(res).data.lastOptionId);
      console.log("lastOptionId", JSON.parse(res).data.lastOptionId);
    } catch (err) {
      console.log(err);
    }
  };

  //const allAlterantives
  const allAlternatives = useAlternative();
  const setAlternatives = useAlternativeUpdate();
  const setLastAlternativeId = useLastAlternativeIdUpdate();
  const setAltOptions = useAlternativeOptionUpdate();
  const allAltOptions = useAlternativeOption();
  const avgAnnSupply = useTotalAvgAnnSupply();
  const setAvgAnnSupply = useTotalAvgAnnSupplyUpdate();
  const getAlternatives = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query:
          "{allAlternatives{creator{id,firstName,lastName},id,name,code,description,createdAt,updatedAt}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("alternatives", JSON.parse(res).data.allAlternatives);
      setAlternatives(JSON.parse(res).data.allAlternatives);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastAlternativeId = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{lastAlternativeId}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastAlternativeId(JSON.parse(res).data.lastAlternativeId);
    } catch (err) {
      console.log(err);
    }
  };

  const getAltOptions = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{allAltOptions{id,altId,optId,checked}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("alternative options", JSON.parse(res).data.allAltOptions);
      setAltOptions(JSON.parse(res).data.allAltOptions);
    } catch (err) {
      console.log(err);
    }
  };

  // const allCriterias = useQuery(ALL_CRITERIAS, { fetchPolicy: "network-only" });
  const allCriterias = useCriteria();
  const setCriterias = useCriteriaUpdate();
  const setLastCriteriaId = useLastCriteriaIdUpdate();
  const getCriterias = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query:
          "{allCriterias{id,name,description, data_source, metric, scoring_level, scoring_assignment, scoring_points, deleted, weight}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/criteria",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("criterias", JSON.parse(res).data.allCriterias);
      setCriterias(JSON.parse(res).data.allCriterias);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastCriteriaId = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{lastCriteriaId}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/criteria",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastCriteriaId(JSON.parse(res).data.lastCriteriaId);
    } catch (err) {
      console.log(err);
    }
  };

  // const allScenarios = useQuery(ALL_SCENARIOS);
  const allScenarios = useScenario();
  const setScenarios = useScenarioUpdate();
  const setLastScenarioId = useLastScenarioIdUpdate();
  const getScenarios = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{allScenarios{id,name,description}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/scenario",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log("scenarios", JSON.parse(res).data.allScenarios);
      setScenarios(JSON.parse(res).data.allScenarios);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastScenarioId = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query: "{lastScenarioId}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/scenario",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setLastScenarioId(JSON.parse(res).data.lastScenarioId);
    } catch (err) {
      console.log(err);
    }
  };
  // const allEvaluatedAlternatives = useQuery(ALL_ALTERNATIVE_EVALUATIONS);
  const allEvaluatedAlternatives = useEvaluatedAlternative();
  const setEvaluatedAlternatives = useEvaluatedAlternativeUpdate();
  const getEvaluatedAlternatives = async () => {
    try {
      let body = JSON.stringify({
        operationName: null,
        query:
          "{allAlternativeEvaluations{id,score,description,critId,altId,scenario{name},deleted,scenarioId}}",
        variables: {},
      });
      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative_evaluation",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      console.log(
        "evaluated alternatives",
        JSON.parse(res).data.allAlternativeEvaluations
      );
      setEvaluatedAlternatives(JSON.parse(res).data.allAlternativeEvaluations);
    } catch (err) {
      console.log(err);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);

  // //profile
  const [showProfile, setShowProfile] = useState(false);
  const toggleProfile = () => {
    setShowAdmin(false);
    setShowProfile(true);
    setShowOptions(false);
    setShowAlternatives(false);
    setShowEvaluation(false);
    setShowAltSummary(false);
    setShowScenario(false);
  };

  // //admin
  const [showAdmin, setShowAdmin] = useState(false);
  const [addUser, setAddUser] = useState(false);

  const closeAddUser = () => setAddUser(false);
  const openAddUser = () => setAddUser(true);
  const toggleAdmin = () => {
    setShowProfile(false);
    setShowAdmin(true);
    setShowOptions(false);
    setShowAlternatives(false);
    setShowEvaluation(false);
    setShowAltSummary(false);
    setShowScenario(false);
  };

  // //options
  const [showOptions, setShowOptions] = useState(false);
  const [addOption, setAddOption] = useState(false);

  const closeAddOption = () => {
    setAddOption(false);
  };
  const openAddOption = () => setAddOption(true);
  const toggleOptions = () => {
    setShowOptions(true);
    setShowProfile(false);
    setShowAdmin(false);
    setShowAlternatives(false);
    setShowEvaluation(false);
    setShowAltSummary(false);
    setShowScenario(false);
  };

  // //alternatives
  const [showAlternatives, setShowAlternatives] = useState(false);
  const [addAlternative, setAddAlternative] = useState(false);
  const openAddAlternative = () => {
    setAddAlternative(true);
  };
  const closeAddAlternative = () => {
    setAddAlternative(false);
  };
  const [updateAlternative, setUpdateAlternative] = useState(false);
  const altId = useRef(null);
  const openUpdateAlternative = (id) => {
    altId.current = id;
    setUpdateAlternative(true);
  };
  const closeUpdateAlternative = () => {
    setUpdateAlternative(false);
  };
  const toggleAlternatives = () => {
    setShowAlternatives(true);
    setShowProfile(false);
    setShowAdmin(false);
    setShowOptions(false);
    setShowEvaluation(false);
    setShowAltSummary(false);
    setShowScenario(false);
  };

  // //evaluations
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [addEvaluation, setAddEvaluation] = useState(false);
  const openAddEvaluation = () => {
    setAddEvaluation(true);
  };
  const closeAddEvaluation = () => {
    setAddEvaluation(false);
  };
  const toggleEvaluation = () => {
    setShowEvaluation(true);
    setShowAlternatives(false);
    setShowProfile(false);
    setShowAdmin(false);
    setShowOptions(false);
    setShowAltSummary(false);
    setShowScenario(false);
  };

  // //alternative summary
  const [showAltSummary, setShowAltSummary] = useState(false);
  const toggleAltSummary = () => {
    setShowAltSummary(true);
    setShowOptions(false);
    setShowProfile(false);
    setShowAdmin(false);
    setShowAlternatives(false);
    setShowEvaluation(false);
    setShowScenario(false);
  };

  const [showScenario, setShowScenario] = useState(false);
  const [addScenario, setAddScenario] = useState(false);
  const openAddScenario = () => {
    setAddScenario(true);
  };
  const closeAddScenario = () => {
    setAddScenario(false);
  };
  const toggleScenario = () => {
    setShowScenario(true);
    setShowAltSummary(false);
    setShowOptions(false);
    setShowProfile(false);
    setShowAdmin(false);
    setShowAlternatives(false);
    setShowEvaluation(false);
  };

  // //sidebar
  const toggleSidebar = () => {
    sidebarOpen ? closeSidebar() : openSidebar();
  };

  // //Logout
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  //header hover effect state
  const [homeHover, setHomeHover] = useState(false);
  const homeOver = () => {
    setHomeHover(true);
  };
  const homeOut = () => {
    setHomeHover(false);
  };
  const [settingsHover, setSettingsHover] = useState(false);
  const settingsOver = () => {
    setSettingsHover(true);
  };
  const settingsOut = () => {
    setSettingsHover(false);
  };
  const [profileHover, setProfileHover] = useState(false);
  const profileOver = () => {
    setProfileHover(true);
  };
  const profileOut = () => {
    setProfileHover(false);
  };
  const [logoutHover, setLogoutHover] = useState(false);
  const logoutOver = () => {
    setLogoutHover(true);
  };
  const logoutOut = () => {
    setLogoutHover(false);
  };

  useEffect(() => {
    getUsers();
    getAlternatives();
    getLastAlternativeId();
    getOptions();
    getLastOptionId();
    getCriterias();
    getLastCriteriaId();
    getScenarios();
    getEvaluatedAlternatives();
    getLastScenarioId();
    getLastUserId();
    getAltOptions();
    // setAvgAnnSupply(allOptions);

    // console.log(avgAnnSupply);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {load ? (
        <div className="flex justify-center w-full min-h-screen items-center bg-wc-blue  text-white gap-24 font-roboto sticky top-0">
          <h2 className="text-2xl">
            Welcome to the Woodard and Curran Planning Tool
          </h2>
          <ClimbingBoxLoader size={40} color={"#A3AEB9"} loading={load} />
        </div>
      ) : (
        <div className="main-content min-h-screen flex ">
          <Sidebar
            toggleAdmin={toggleAdmin}
            toggleProfile={toggleProfile}
            toggleOptions={toggleOptions}
            toggleAlternatives={toggleAlternatives}
            showSidebar={sidebarOpen}
            toggleSidebar={toggleSidebar}
            toggleEvaluation={toggleEvaluation}
            toggleScenario={toggleScenario}
            toggleAltSummary={toggleAltSummary}
            logout={logout}
          />

          <div className="w-full bg-wc-green bg-cover dark:bg-gray-700 top-0 fixed  flex justify-end items-center text-white shadow-sm font-semibold tracking-wide pr-[8rem] gap-12 z-50 p-">
            {/* <div className="bg-white-see text-wc-blue dark:bg-wc-green dark:text-white w-full h-full flex justify-end items-center">
              Woodard & Curran Integrated Planning Tool
            </div> */}

            {/* <span>Woodard & Curran Integrated Planning Tool</span> */}
            {/* <div className="flex gap-4 items-center">
              <span>{`${localStorage.getItem(
                "firstName"
              )} ${localStorage.getItem("lastName")}`}</span>
              <span>
                {" "}
                {localStorage.getItem("role") === "USER" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-yellow-200 dark:bg-yellow-400 rounded-lg bg-opacity-50">
                    USER
                  </span>
                )}
                {localStorage.getItem("role") === "ADMIN" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-green-200 dark:bg-green-400 rounded-lg bg-opacity-50 ">
                    Admin
                  </span>
                )}
                {localStorage.getItem("role") === "GUEST" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-gray-200 dark:bg-gray-50 rounded-lg bg-opacity-50 ">
                    Guest
                  </span>
                )}
                {localStorage.getItem("role") === "COUNTY" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-blue-200 dark:bg-blue-400 rounded-lg bg-opacity-50 ">
                    AGENCY
                  </span>
                )}
              </span>
            </div> */}
            <AnimatePresence exitBeforeEnter initial={false}>
              <ul className="text-white flex gap-8 ">
                <li
                  className="hover:text-wc-blue  cursor-pointer rounded-lg relative"
                  onMouseOver={homeOver}
                  onMouseOut={homeOut}
                >
                  <AiOutlineHome size="32" />
                  {/* {homeHover && (
                    <motion.div
                      className="absolute bottom-[-2.7rem] left-[-.7rem] z-50 rounded bg-wc-green p-2 text-white border-white border-2 shadow-md"
                      variants={slideIn}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      Home
                    </motion.div>
                  )} */}
                </li>
                <li
                  className="hover:text-wc-blue  cursor-pointer rounded-lg relative"
                  onMouseOver={settingsOver}
                  onMouseOut={settingsOut}
                >
                  <MdOutlineSettings size="32" />
                  {/* {settingsHover && (
                    <motion.div
                      className="absolute bottom-[-2.7rem] left-[-.7rem] z-50 rounded bg-wc-green p-2 text-white border-white border-2 shadow-md"
                      variants={slideIn}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      Settings
                    </motion.div>
                  )} */}
                </li>
                <li
                  className="hover:text-wc-blue  cursor-pointer rounded-lg relative"
                  onMouseOver={profileOver}
                  onMouseOut={profileOut}
                  onClick={toggleProfile}
                >
                  <AiOutlineUser size="32"></AiOutlineUser>
                  {/* {profileHover && (
                    <motion.div
                      className="absolute bottom-[-2.7rem] left-[-.7rem] z-50 rounded bg-wc-green p-2 text-white border-white border-2 shadow-md"
                      variants={slideIn}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      Profile
                    </motion.div>
                  )} */}
                </li>
                <li
                  className="hover:text-wc-blue  cursor-pointer rounded-lg relative"
                  onMouseOver={logoutOver}
                  onMouseOut={logoutOut}
                  onClick={logout}
                >
                  <AiOutlineLogout size="32" />
                  {/* {logoutHover && (
                    <motion.div
                      className="absolute bottom-[-2.7rem] left-[-.7rem] z-50 rounded bg-wc-green p-2 text-white border-white border-2 shadow-md"
                      variants={slideIn}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      Logout
                    </motion.div>
                  )} */}
                </li>
                <li>
                  {" "}
                  <div
                    className="cursor-pointer rounded-3xl h-8 w-8 bg-yellow-500 dark:bg-blue-500 transistion duration-200 flex items-center justify-center"
                    onClick={() => {
                      setTheme(colorTheme);
                    }}
                  >
                    {colorTheme === "light" ? (
                      <BsMoonStars size="18" />
                    ) : (
                      <BsSunFill size="18" />
                    )}
                  </div>
                </li>
              </ul>
            </AnimatePresence>
            <div className="flex gap-4 items-center">
              <span className="text-lg">{`${localStorage.getItem(
                "firstName"
              )} ${localStorage.getItem("lastName")}`}</span>
              <span>
                {" "}
                {localStorage.getItem("role") === "USER" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-yellow-200 dark:bg-yellow-400 rounded-lg bg-opacity-50">
                    USER
                  </span>
                )}
                {localStorage.getItem("role") === "ADMIN" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-green-200 dark:bg-green-400 rounded-lg bg-opacity-50 ">
                    Admin
                  </span>
                )}
                {localStorage.getItem("role") === "GUEST" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-gray-200 dark:bg-gray-50 rounded-lg bg-opacity-50 ">
                    Guest
                  </span>
                )}
                {localStorage.getItem("role") === "COUNTY" && (
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-blue-200 dark:bg-blue-400 rounded-lg bg-opacity-50 ">
                    AGENCY
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="flex-1 bg-gray-100 dark:bg-gray-900 transition duration-500  text-white justify-center overflow-auto">
            <AnimatePresence exitBeforeEnter initial={false}>
              {showAdmin && localStorage.getItem("role") === "ADMIN" && (
                <AdminTable
                  addUser={addUser}
                  openAddUser={openAddUser}
                  sidebarOpen={sidebarOpen}
                  user_list={allUsers}
                  setUsers={setUsers}
                  allUsers={allUsers}
                />
              )}

              {showProfile && <ProfileSettings />}
              {showOptions && (
                <OptionsTable
                  openAddOption={openAddOption}
                  addOption={addOption}
                  sidebarOpen={sidebarOpen}
                  options_list={allOptions}
                  setOptions={setOptions}
                  allAltOptions={allAltOptions}
                  setAltOptions={setAltOptions}
                  allAlternatives={allAlternatives}
                />
              )}
              {showAlternatives && (
                <AltTable
                  sidebarOpen={sidebarOpen}
                  openAddAlternative={openAddAlternative}
                  openUpdateAlternative={openUpdateAlternative}
                  allAlternatives={allAlternatives}
                  allCriterias={allCriterias}
                  allOptions={allOptions}
                  allAltOptions={allAltOptions}
                />
              )}
              {showEvaluation && (
                <EvalTable
                  sidebarOpen={sidebarOpen}
                  openAddEvaluation={openAddEvaluation}
                  allCriterias={allCriterias}
                  setCriterias={setCriterias}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                />
              )}
              {showScenario && (
                <ScenarioTable
                  sidebarOpen={sidebarOpen}
                  openAddScenario={openAddScenario}
                  allScenarios={allScenarios}
                  setScenarios={setScenarios}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                />
              )}
              {showAltSummary && allScenarios && allEvaluatedAlternatives && (
                <AlternativeSummaryTable
                  sidebarOpen={sidebarOpen}
                  allScenarios={allScenarios}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setCriterias={setCriterias}
                  allCriterias={allCriterias}
                  allAlternatives={allAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                  allOptions={allOptions}
                  allAltOptions={allAltOptions}
                />
              )}
            </AnimatePresence>

            <AnimatePresence exitBeforeEnter={true} initial={false}>
              {addUser && (
                <AddUserModal
                  handleClose={closeAddUser}
                  setUsers={setUsers}
                  allUsers={allUsers}
                />
              )}
              {addOption && (
                <AddOptionModal
                  handleClose={closeAddOption}
                  setOptions={setOptions}
                  allOptions={allOptions}
                  allAlternatives={allAlternatives}
                  setAltOptions={setAltOptions}
                  allAltOptions={allAltOptions}
                />
              )}
              {addAlternative && (
                <AddAlternativeModal
                  handleClose={closeAddAlternative}
                  allOptions={allOptions}
                  setOptions={setOptions}
                  setAlternatives={setAlternatives}
                  allAlternatives={allAlternatives}
                  allCriterias={allCriterias}
                  setCriterias={setCriterias}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                  allScenarios={allScenarios}
                />
              )}
              {updateAlternative && (
                <UpdateAlternativeModal
                  handleClose={closeUpdateAlternative}
                  alt={allAlternatives.filter((el) => {
                    return el.id === altId.current;
                  })}
                  setAlternatives={setAlternatives}
                  allAlternatives={allAlternatives}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                  allAltOptions={allAltOptions}
                  setAltOptions={setAltOptions}
                />
              )}
              {addEvaluation && (
                <AddEvalModal
                  handleClose={closeAddEvaluation}
                  setCriterias={setCriterias}
                  allCriterias={allCriterias}
                  setAlternatives={setAlternatives}
                  allAlternatives={allAlternatives}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                  allScenarios={allScenarios}
                  setScenarios={setScenarios}
                />
              )}
              {addScenario && (
                <AddScenarioModal
                  handleClose={closeAddScenario}
                  setScenarios={setScenarios}
                  allScenarios={allScenarios}
                  allEvaluatedAlternatives={allEvaluatedAlternatives}
                  setEvaluatedAlternatives={setEvaluatedAlternatives}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Dashboard;
