import React, { useState } from "react";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Criteria = ({ criteria, openDeleteModal, openUpdateModal, index }) => {
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };
  return (
    <tr
      className={`rounded-lg text-black dark:text-white  ${
        index % 2 === 0
          ? "dark:bg-gray-700 bg-gray-50"
          : "dark:bg-gray-500 bg-white"
      }`}
    >
      {/* <td className="p-3 text-lg font-bold hover:underline cursor-pointer whitespace-nowrap bg-wc-green text-white">
        {criteria.id}
      </td> */}
      <td className="p-3 text-lg font-bold wrap w-[20rem] bg-wc-green text-white">
        {criteria.name}
      </td>
      <td className="p-3 text-lg wrap w-[20rem] border">
        {criteria.description}
      </td>
      <td className="p-3 text-lg  wrap w-[10rem] border">{criteria.metric}</td>
      <td className="p-3 text-lg  wrap w-[10rem] border">
        {criteria.data_source}
      </td>
      <td className="p-3 text-lg  wrap w-[20rem] overflow-auto border">
        <ul>
          {criteria.scoring_points.map((point, index) => {
            return (
              <li key={index}>{`${parseFloat(point).toFixed(2)}: ${
                criteria.scoring_assignment[index]
              }`}</li>
            );
          })}
        </ul>
      </td>
      <td className="p-3 text-lg w-[10rem] border">
        {criteria.weight === null ? 0 : criteria.weight}
      </td>

      <td className="p-3 text-lg  flex gap-1 w-[10rem] ">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openUpdateModal(criteria.id);
          }}
          onMouseOver={editMouseOver}
          onMouseOut={editMouseOut}
        >
          <BsFillPencilFill size="18" className="cursor-pointer" />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openDeleteModal(criteria.id);
          }}
          onMouseOver={deleteMouseOver}
          onMouseOut={deleteMouseOut}
        >
          <BsFillTrashFill size="18" className="cursor-pointer" />
          {deleteHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              delete
            </div>
          )}
        </motion.button>
      </td>
    </tr>
  );
};

export default Criteria;
