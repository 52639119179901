import React from "react";
import Backdrop from "../Misc/Backdrop";
import { motion } from "framer-motion";

import { useState } from "react";

//animations
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const UpdateAlternativeModal = ({
  handleClose,
  alt,
  setAlternatives,
  allAlternatives,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
  allAltOptions,
  setAltOptions,
}) => {
  const [altName, setAltName] = useState(alt[0].name);
  const [altCode, setAltCode] = useState(alt[0].code);
  const [description, setDescription] = useState(alt[0].description);

  //methods
  const saveAlt = async () => {
    try {
      if (
        altName.trim() === "" ||
        altCode.trim() === "" ||
        description.trim() === ""
      ) {
        alert("Missing required fields");
      } else {
        //gloabl alternative update
        let updatedAlternatives = allAlternatives;
        for (const [index, alternative] of updatedAlternatives.entries()) {
          if (alternative.id === parseInt(alt[0].id)) {
            updatedAlternatives[index] = {
              id: alt[0].id,
              name: altName,
              code: altCode,
              description: description,
            };
          }
        }
        setAlternatives(updatedAlternatives);
        ///////////
        handleClose();
        //////save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation updateAlternative{ updateAlternative(data:{id:${parseInt(
            alt[0].id
          )}, name:"${altName}", code:"${altCode}", description:"${description}"}){id,name,code,description}}`,
        });

        await fetch(
          "https://intplantoolapi.azurewebsites.net/api/alternative",
          {
            body: body,
            method: "POST",
          }
        );
        //////////
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeAlt = async () => {
    try {
      ///update alternative global state
      const updatedAlt = allAlternatives.filter((el) => {
        return el.id !== alt[0].id;
      });
      setAlternatives(updatedAlt);
      const updatedEvalAlt = allEvaluatedAlternatives.filter((el) => {
        return el.altId !== alt[0].id;
      });
      setEvaluatedAlternatives(updatedEvalAlt);
      const updatedAltOpts = allAltOptions.filter((el) => {
        return el.altId !== alt[0].id;
      });
      setAltOptions(updatedAltOpts);
      ////////
      handleClose();
      ////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation deleteAlternative{ deleteAlternative(id:${alt[0].id}){id}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/alternative", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className=" rounded-xl sm:w-2/12 min-w-3/12 bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-12 flex flex-col gap-4 line-clamp-7 min-w-12">
          <h2 className="text-2xl ">Update alternative </h2>

          <div className="name flex flex-col">
            <label className="text-sm">Alternative Name</label>
            <input
              type="text"
              className="rounded h-text-input border-2 border-gray-300 dark:text-black"
              placeholder=" Alternative name"
              defaultValue={alt[0].name}
              onChange={(e) => setAltName(e.target.value)}
            />
          </div>
          <div className="name flex flex-col">
            <label className="text-sm">Alternative Code</label>
            <input
              type="text"
              className="rounded h-text-input border-2 border-gray-300 dark:text-black"
              placeholder=" Alternative code"
              defaultValue={alt[0].code}
              onChange={(e) => setAltCode(e.target.value)}
            />
          </div>
          <div className="name flex flex-col">
            <label className="text-sm">Description</label>
            <textarea
              rows="4"
              className="rounded border-2 border-gray-300 dark:text-black"
              placeholder=" Description"
              defaultValue={alt[0].description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="border-2 rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center h-button text-xl"
            onClick={saveAlt}
          >
            Save
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="border-2 rounded-md p-2 bg-red-500 text-white flex items-center justify-center  h-button text-xl"
            onClick={removeAlt}
          >
            Delete
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateAlternativeModal;
