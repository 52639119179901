import React, { createContext, useState, useContext } from "react";

const OptionContext = createContext();
const OptionUpdateContext = createContext();

const LastOptionIdContext = createContext();
const LastOptionIdUpdateContext = createContext();

export const useOption = () => {
  return useContext(OptionContext);
};

export const useOptionUpdate = () => {
  return useContext(OptionUpdateContext);
};

export const useLastOptionId = () => {
  return useContext(LastOptionIdContext);
};

export const useLastOptionIdUpdate = () => {
  return useContext(LastOptionIdUpdateContext);
};

export const OptionProvider = ({ children }) => {
  const [option, setOption] = useState(null);
  const updateOption = (option) => {
    setOption(option);
  };

  const [lastId, setLastId] = useState(null);
  const updateLastId = (id) => {
    setLastId(id);
  };

  return (
    <>
      <OptionContext.Provider value={option}>
        <OptionUpdateContext.Provider value={updateOption}>
          <LastOptionIdContext.Provider value={lastId}>
            <LastOptionIdUpdateContext.Provider value={updateLastId}>
              {children}
            </LastOptionIdUpdateContext.Provider>
          </LastOptionIdContext.Provider>
        </OptionUpdateContext.Provider>
      </OptionContext.Provider>
    </>
  );
};
