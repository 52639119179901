import React, { useState } from "react";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Scenario = ({
  id,
  scenario,
  index,
  openDeleteModal,
  openUpdateModal,
}) => {
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };
  return (
    <tr
      className={`rounded-lg text-black dark:text-white text-center ${
        index % 2 === 0
          ? "dark:bg-gray-700 bg-gray-50"
          : "dark:bg-gray-500 bg-white"
      }`}
    >
      {/* <td className="p-3 text-lg font-bold whitespace-nowrap bg-wc-green text-white">
        {scenario.id}
      </td> */}
      <td className="p-3 text-lg font-bold whitespace-nowrap   dark:text-white bg-wc-green text-white">
        {scenario.name}
      </td>
      <td className="p-3 text-lg font-bold whitespace-nowrap  text-black dark:text-white">
        {scenario.description}
      </td>
      <td className="p-3 text-lg font-bold whitespace-nowrap  text-black dark:text-white flex gap-1 justify-center">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openUpdateModal(scenario.id);
          }}
          onMouseOver={editMouseOver}
          onMouseOut={editMouseOut}
        >
          <BsFillPencilFill size="18" className="cursor-pointer" />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openDeleteModal(scenario.id);
          }}
          onMouseOver={deleteMouseOver}
          onMouseOut={deleteMouseOut}
        >
          <BsFillTrashFill size="18" className="cursor-pointer" />
          {deleteHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              delete
            </div>
          )}
        </motion.button>
      </td>
    </tr>
  );
};

export default Scenario;
