import React from "react";
import { Radar } from "react-chartjs-2";

const RadarChart = ({ chartData, labels }) => {

  return (
    <div className="w-6/12  rounded-lg shadow-xl relative mt-12 bg-white">
      <div className="w-full aboslute top-0 bg-wc-green h-12 rounded-t-lg flex justify-center items-center text-lg">
        <h2>Criteria Comparison</h2>
      </div>
      <Radar 
        className="dark:bg-gray-500"
        data={{
          labels: labels,
          datasets: chartData,
        }}
        options={{
          responsive: true,
          layout: { padding: 25 },
          scales: {
            r: {
              min: 0,
              max: 0.5,
              ticks: {
                stepSize: 0.1,
              },
            },
          },
          plugins: {
            legend: {
              // position: 'top',
              // labels: { boxWidth: 10 },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default RadarChart;