import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as CharJS } from "chart.js/auto";

const BarChart = ({ chartData, labels }) => {
  console.log("chartData", chartData);
  return (
    <div className="w-6/12  rounded-lg shadow-xl relative mt-12 bg-white dark:bg-gray-500">
      <div className="w-full aboslute top-0 bg-wc-green h-12 rounded-t-lg flex justify-center items-center text-lg">
        <h2>Weighted Alternatives</h2>
      </div>
      <Bar
        className="dark:bg-gray-500"
        data={{
          labels: labels,
          datasets: chartData,
        }}
        options={{
          layout: { padding: 25 },
          plugins: {
            legend: {
              labels: { boxWidth: 10 },
              align: "middle",
              position: "right",
            },
          },
          responsive: true,
          scales: {
            x: { stacked: true, beginAtZero: true, max: 0.8, stepSize: 0.1 },
            y: { stacked: true, grid: { display: false } },
          },
          indexAxis: "y",
        }}
      />
    </div>
  );
};

export default BarChart;
