import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Criteria from "./Criteria";
import DeleteModal from "../Misc/DeleteModal";
import UpdateModal from "./UpdateEvaluation";

import { AiOutlineFileAdd } from "react-icons/ai";

const EvalutationTable = ({
  sidebarOpen,
  openAddEvaluation,
  allCriterias,
  setCriterias,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
}) => {
  useEffect(() => {
    console.log("added Criterias");
  }, [allCriterias]);
  //local state
  const [deleteId, setDeleteId] = useState(null);

  //delete criteria
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const deleteCriteria = async (id) => {
    try {
      //updated global state
      const updatedCriterias = allCriterias.filter((el) => {
        return el.id !== id;
      });
      setCriterias(updatedCriterias);
      const updatedEvalAlt = allEvaluatedAlternatives.filter((el) => {
        return el.critId !== id;
      });
      setEvaluatedAlternatives(updatedEvalAlt);
      ////////
      ///////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation deleteCritiera{deleteCriteria(id:${id}){id}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/criteria", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  //update criteria
  const [updateId, setUpdateId] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = (id) => {
    setUpdateId(id);
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  //table totals
  const [manualCount, setManualCount] = useState(null);
  const [automatedCount, setAutomatedCount] = useState(null);
  const [quantitative, setQuantitative] = useState(null);
  const [qualitative, setQualitative] = useState(null);

  useEffect(() => {
    let mc = 0;
    let ac = 0;
    let qualitative = 0;
    let quantitative = 0;
    for (const crit of allCriterias) {
      if (crit.data_source === "MANUAL") {
        mc += 1;
      } else if (crit.data_source === "AUTOMATED") {
        ac += 1;
      }

      if (crit.metric === "Qualitative") {
        qualitative += 1;
      } else if (crit.metric === "Quantitative") {
        quantitative += 1;
      }
    }
    setManualCount(mc);
    setAutomatedCount(ac);
    setQualitative(qualitative);
    setQuantitative(quantitative);
  }, [allCriterias]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`eval-table min-h-full  duration-400 ease-in-out transition-spacing p-16 font-roboto dark:bg-gray-900 flex gap-4 ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      <div className="flex justify-between h-[70rem] w-[15rem] bg-white dark:bg-gray-700 rounded-md">
        <div className="flex flex-col items-center gap-4 w-fit text-black rounded-md shadow-lg  dark:text-white ">
          <h2 className="text-2xl font-semibold tracking-wide  bg-wc-green p-4 rounded-t-md text-white">
            Evaluation Criteria Summary
          </h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-3 bg-wc-green h-button text-white flex items-center justify-center w-38 gap-2"
            onClick={openAddEvaluation}
          >
            <AiOutlineFileAdd size="24" />
            Add Evaluation
          </motion.button>
          <hr className="w-10/12 text-black" />

          {allCriterias && (
            <span className="text-black dark:text-black bg-blue-200 p-2 rounded-md">
              {`${allCriterias.length} ${
                allCriterias.length === 1 ? "criteria" : "criterias"
              }`}
            </span>
          )}
          <hr className="w-10/12 text-black" />
          <div className="flex flex-col gap-2">
            <span className="font-semibold tracking-wider">Data Source</span>
            <ul className="flex flex-col gap-1">
              <li className="bg-green-200 p-1 rounded-sm text-center">
                {`
                  ${
                    allCriterias.filter((el) => {
                      return el.data_source === "MANUAL";
                    }).length
                  } manual
                  `}
              </li>
              <li className="bg-orange-200 p-1 rounded-sm text-center">
                {`
                  ${
                    allCriterias.filter((el) => {
                      return el.data_source === "AUTOMATED";
                    }).length
                  } automated`}
              </li>
            </ul>
          </div>

          <hr className="w-10/12 text-black" />
          <div className="flex flex-col gap-2">
            <span className="font-semibold tracking-wider">Metric</span>
            <ul className="flex flex-col gap-1">
              <li className="bg-green-200 p-1 rounded-sm text-center">
                {`
                  ${
                    allCriterias.filter((el) => {
                      return el.metric === "Qualitative";
                    }).length
                  } qualitative
                  `}
              </li>
              <li className="bg-orange-200 p-1 rounded-sm text-center">
                {`
                  ${
                    allCriterias.filter((el) => {
                      return el.metric === "Quantitative";
                    }).length
                  } quantitative
                  `}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="overflow-auto rounded-lg shadow-lg h-[70rem] w-11/12 bg-white dark:bg-gray-700">
        <table className="min-w-full shadow-md">
          <thead className="bg-wc-green border-b-2 border-gray-200 text-white">
            <tr>
              {/* <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                id
              </th> */}
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                Criteria Name
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                Description
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                Metric
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap ">
                Data Source
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                Scoring Assignment
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap">
                Weight
              </th>
              <th className="p-3 text-lg font-semibod tracking-wide text-left w-[10rem]">
                Actions
              </th>
            </tr>
          </thead>
          {allCriterias.length > 0 && (
            <tbody className="divide-y">
              {allCriterias.loading && (
                <tr>
                  <td>
                    <span>loading evaluations...</span>
                  </td>
                </tr>
              )}
              {allCriterias.error && (
                <span>error retrieving evaluations...</span>
              )}
              {allCriterias &&
                allCriterias.map((criteria, index) => {
                  return (
                    <Criteria
                      criteria={criteria}
                      openDeleteModal={openDeleteModal}
                      openUpdateModal={openUpdateModal}
                      deleteModal={deleteModal}
                      id={deleteId}
                      key={index}
                      index={index}
                    />
                  );
                })}
            </tbody>
          )}
        </table>
      </div>

      <AnimatePresence exitBeforeEnter initial={false}>
        {deleteModal && (
          <DeleteModal
            handleClose={closeDeleteModal}
            text={"Are you sure you want to delete this evaluation criteria?"}
            deleteFunc={deleteCriteria}
            id={deleteId}
          />
        )}
        {updateModal && (
          <UpdateModal
            handleClose={closeUpdateModal}
            id={updateId}
            setCriterias={setCriterias}
            allCriterias={allCriterias}
            criteria={allCriterias.filter((el) => {
              return el.id === updateId;
            })}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default EvalutationTable;
