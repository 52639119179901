import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import BarChart from "../Charts/BarChart";
import ScatterPlot from "../Charts/ScatterPlot";

import AlternativeSummary from "./AlternativeSummary";
import RadarChart from "../Charts/RadarChart";

const AlternativeSummaryTable = ({
  sidebarOpen,
  allScenarios,
  allEvaluatedAlternatives,
  allAlternatives,
  allCriterias,
  setCriterias,
  setEvaluatedAlternatives,
  allOptions,
  allAltOptions,
}) => {
  //Weightings logic
  const [weightings, setWeightings] = useState([]);
  useEffect(() => {
    console.log(
      "AllCrits",
      allCriterias,
      "allEvalAltss",
      allEvaluatedAlternatives
    );
  }, [allCriterias, allEvaluatedAlternatives]);
  const updateWeightings = async (e, index, id) => {
    try {
      const tempArr = weightings;
      tempArr[index] = e;
      setWeightings(tempArr);
      //udpate criteria global state
      const updatedCriterias = allCriterias;
      for (const [index, crit] of updatedCriterias.entries()) {
        if (crit.id === id) {
          updatedCriterias[index].weight = weightings[index] / 100;
        }
      }
      // setCriterias(updatedCriterias);
      // console.log("udpatedCrits", allCriterias);
      /////////
      ///////save to db
      let body = JSON.stringify({
        operation: null,
        query: `mutation updateCriteria{updateCriteria(data:{id:${id}, name:"${"name"}", description:"${"description"}", data_source:"${"data_source"}", metric:"${"metric"}", scoring_level:${0}, scoring_points:"${[
          "hello",
        ]}", scoring_assignment:"${["hello"]}", weight:${parseInt(
          weightings[index]
        )}}){name}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/criteria", {
        body: body,
        method: "POST",
      });

      body = JSON.stringify({
        operation: null,
        query: `{allCriterias{id, name, description, data_source, metric, scoring_level, scoring_points, scoring_assignment, weight }}`,
      });

      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/criteria",
        {
          body: body,
          method: "POST",
        }
      );
      let res = await req.text();

      setCriterias(JSON.parse(res).data.allCriterias);
      ////////
    } catch (err) {
      console.log(err);
    }
  };

  //scenario logic
  const [scenarioId, setScenarioId] = useState(0);
  const selectScenario = (id) => {
    setScenarioId(id);
  };

  let [filteredEvaluations, setFilteredEvaluations] = useState([]);
  const [barData, setBarData] = useState([]);
  const [radarData, setRadarData] = useState([]);
  const [barLabels, setBarLabels] = useState(
    allAlternatives.map((alt) => alt.name)
  );
  const [radarLabels, setRadarLabels] = useState(
    allCriterias.map((crit) => crit.name)
  );
  const barColors = [
    "#70e0de",
    "#ebd363",
    "#f97766",
    "#14b0f9",
    "#6101e8",
    "#a6a4d3",
    "#d48fa6",
    "#3bcc1d",
    "#106f34",
    "#b16d31",
    "#C3F8FF",
    "#FFF6BF",
  ];
  useEffect(() => {
    if (allEvaluatedAlternatives) {
      let temp = allEvaluatedAlternatives.filter((evalAlt) => {
        if (!scenarioId) {
          return evalAlt.scenarioId === null;
        }
        return evalAlt.scenarioId === parseInt(scenarioId);
      });

      setFilteredEvaluations(temp);

      const barTemp = [];
      if (filteredEvaluations.length > 0) {
        //generate criteria objects for bar graph
        for (const [i, c] of allCriterias.entries()) {
          barTemp.push({
            label: c.name,
            data: [],
            backgroundColor: barColors[i],
            barPercentage: 0.5,
            borderColor: "black",
            borderWidth: 1,
          });
        }
        for (const alt of allAlternatives) {
          for (const [index, crit] of allCriterias.entries()) {
            const ea = temp.filter((el) => {
              return el.altId === alt.id && el.critId === crit.id;
            });

            if (ea[0]) {
              let tempScore = 0;
              if (ea[0].score !== "")
                tempScore = parseFloat(ea[0].score.split(":")[0]);
              barTemp[index].data.push(crit.weight * tempScore);
            }
          }
        }
        setBarData(barTemp);
      }

      const radarTemp = [];
      if (filteredEvaluations.length > 0) {
        //generate criteria objects for radar graph
        for (const [i, c] of allAlternatives.entries()) {
          radarTemp.push({
            label: c.description,
            data: [],
            borderColor: barColors[i],
            borderWidth: 2,
            pointBorderColor: barColors[i],
            pointBackgroundColor: barColors[i],
            backgroundColor: barColors[i],
            pointRadius: 5,
            fill: false,
          });
        }
        for (const crit of allCriterias) {
          for (const [index, alt] of allAlternatives.entries()) {
            const ea = temp.filter((el) => {
              return el.altId === alt.id && el.critId === crit.id;
            });

            if (ea[0]) {
              let tempScore = 0;
              if (ea[0].score !== "")
                tempScore = parseFloat(ea[0].score.split(":")[0]);
              radarTemp[index].data.push(crit.weight * tempScore);
            }
          }
        }
        setRadarData(radarTemp);
      }


    }
  }, [scenarioId, allEvaluatedAlternatives, allCriterias]);

  const [interestRate, setInterestRate] = useState(0);
  //in years
  const [payPeriod, setPayPeriod] = useState(0);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`alt-summary-table  dark:bg-gray-900 min-h-full duration-400 ease-in-out transition-spacing p-16 font-roboto ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      <div className="flex flex-col">
        <div className="flex gap-4 h-[60rem]">
          <div className="flex justify-between ">
            <div className="flex flex-col gap-4 items-center w-fit text-black dark:text-white rounded-md shadow-lg bg-white dark:bg-gray-700">
              <h2 className="text-2xl font-semibold tracking-wide  bg-wc-green rounded-t-md text-white p-4">
                Alternatives Summary
              </h2>

              {allAlternatives && (
                <span className="text-black dark:text-white bg-blue-200 p-2 rounded-md">
                  {`${allAlternatives.length} ${
                    allAlternatives.length === 1
                      ? "alternative"
                      : "alternatives"
                  }`}
                </span>
              )}
              {allCriterias && (
                <span className="text-black dark:text-white bg-green-200 p-2 rounded-md">
                  {`${allCriterias.length} criteria`}
                </span>
              )}
              <hr className="text-black w-10/12" />
              <div className="flex justify-center items-center gap-4">
                {allScenarios && (
                  <select
                    className="h-text-input text-black rounded border-2 border-gray-300 mb-4"
                    onChange={(e) => {
                      console.log("scenario", e.target.value);
                      selectScenario(e.target.value);
                    }}
                  >
                    <option value={0}>Select a scenario</option>
                    {allEvaluatedAlternatives &&
                      allScenarios.map((scenario, index) => {
                        return (
                          <option value={scenario.id} key={index}>
                            {scenario.name}
                          </option>
                        );
                      })}
                  </select>
                )}
              </div>
              <hr className="text-black w-10/12" />
              <div className="flex flex-col gap-2 p-4">
                <span className="tracking-wider">
                  Calculate Annual Capital Cost
                </span>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <label htmlFor="">Annual Rate</label>

                    <span>{interestRate}%</span>
                  </div>
                  <input
                    type="range"
                    className="border-2 border-grey-100 rounded-md"
                    defaultValue={0}
                    onChange={(e) => {
                      setInterestRate(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <label htmlFor="">Payback Periods(years)</label>
                    <span>{payPeriod}</span>
                  </div>
                  <input
                    type="range"
                    className="border-2 border-grey-100  rounded-md"
                    defaultValue={0}
                    onChange={(e) => {
                      setPayPeriod(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="flex justify-center items-center gap-4">
            {allScenarios && (
              <select
                className="h-text-input text-black rounded border-2 border-gray-300"
                onChange={(e) => {
                  console.log("scenario", e.target.value);
                  selectScenario(e.target.value);
                }}
              >
                <option value={0}>Select a scenario</option>
                {allEvaluatedAlternatives &&
                  allScenarios.map((scenario, index) => {
                    return (
                      <option value={scenario.id} key={index}>
                        {scenario.name}
                      </option>
                    );
                  })}
              </select>
            )}
          </div> */}
          </div>

          <div className="overflow-auto rounded-lg shadow-lg h-[60rem] w-full bg-white dark:bg-gray-700">
            <table className="min-w-full shadow-md">
              <thead className="bg-wc-green border-b-2 border-gray-200 text-white sticky top-0">
                <tr className="">
                  <th className="p-3 text-lg font-semibold tracking-wide whitespace-nowrap text-center">
                    Alternatives
                  </th>
                  {allCriterias &&
                    allCriterias.map((criteria, index) => {
                      return (
                        <th
                          className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center"
                          key={index}
                        >
                          {criteria.name}
                        </th>
                      );
                    })}
                  <th>Total Scores</th>
                  <th>Total Unit Cost ($/AF)</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                <tr className="dark:bg-gray-500">
                  <td className="p-3 text-lg font-semibod tracking-wide whitespace-nowrap text-center bg-wc-green sticky left-0">
                    Weightings
                  </td>
                  {allCriterias &&
                    allCriterias.map((criteria, index) => {
                      return (
                        <td
                          className="text-center text-black p-3 border"
                          key={index}
                        >
                          <input
                            type="text"
                            className="border-2 border-gray-300 w-12 h-text-input text-center mr-2"
                            defaultValue={criteria.weight * 100}
                            onChange={(e) => {
                              updateWeightings(
                                e.target.value,
                                index,
                                criteria.id
                              );
                            }}
                          />
                          <span>%</span>
                        </td>
                      );
                    })}
                  <td className="text-black text-center dark:text-white">
                    Total Score = weight x score
                  </td>
                  {/* <td className="text-black text-center dark:text-white">
                    Total Unit Cost ($/AF)
                  </td> */}
                </tr>
                {allAlternatives &&
                  allEvaluatedAlternatives &&
                  filteredEvaluations &&
                  allCriterias &&
                  allScenarios &&
                  allAlternatives.map((alt, index) => {
                    return (
                      <AlternativeSummary
                        key={index}
                        alternative={alt}
                        allCriterias={allCriterias}
                        index={index}
                        weightings={weightings}
                        allEvaluatedAlternatives={filteredEvaluations}
                        evaluatedAlternatives={filteredEvaluations.current}
                        scenarioId={scenarioId}
                        scenarios={allScenarios}
                        setEvaluatedAlternatives={setEvaluatedAlternatives}
                        allOptions={allOptions}
                        allAltOptions={allAltOptions}
                        interestRate={interestRate}
                        payPeriod={payPeriod}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex gap-4">
          <BarChart chartData={barData} labels={barLabels} />
          {/* <ScatterPlot labels={barLabels} /> */}
          <RadarChart chartData={radarData} labels={radarLabels}/>
        </div>
      </div>
    </motion.div>
  );
};

export default AlternativeSummaryTable;
