import React from "react";
import LoginForm from "../components/User/LoginForm";
import Footer from "../components/Misc/Footer.js";
import { FcCheckmark } from "react-icons/fc";
import { motion } from "framer-motion";

const LandingPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className="sm:block md:flex m-h-full relative font-roboto">
          <div className="bg-wc bg-cover basis-8/12 sm:block ">
            <div className="w-full h-full  flex justify-center items-center bg-opaq">
              <div className=" w-10/12 flex flex-col gap-12 text-white">
                <h1 className="text-7xl tracking-wider font-bold">
                  Integrated Planning Tool
                </h1>
                <p className="text-3xl tracking-wider leading-10">
                  Woodard & Curran has developed the Integrated Planning (IP)
                  Tool as a cohesive evaluation platform that integrates
                  qualitative modeling outputs with qualitative methods into an
                  weighted scoring matrix for alternative comparative analysis.
                  The IP Tool uses a web-based interface allowing multiple-user
                  access to supply options, alternative portfolios, evaluation
                  criteria and methods without the need for additional software
                  licenses nor expertise. This creates a dynamic and interactive
                  planning experience that allows the exploration of
                  sensitivities and the basis for evaluating results live with
                  key decision makers and stakeholders to support alternative
                  portfolio selection and implementation planning.
                </p>
                {/* <div className="flex text-center text-3xl font-semibold">
                  <div className="flex flex-col basis-6/12 text-center gap-12">
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      Easily Manage Users
                    </div>
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      Query Spatial Data
                    </div>
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      Manage Entities
                    </div>
                  </div>
                  <div className="flex flex-col basis-6/12 gap-8">
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      <div className="flex flex-col">
                        <p>Easily View Water Quality</p>
                        <span className="p-1.5 text-sm font-medium uppercase text-white bg-orange-500 rounded-lg w-8/12">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      Import Data Sets In Seconds
                    </div>
                    <div className="flex gap-1">
                      <div className="rounded-full h-8 bg-white">
                        <FcCheckmark size="32" />
                      </div>
                      Visualize with stunning filters
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="w-full aspect-w-960 aspect-h-300 bg-no-repeat bg-center bg-cover h-screen sm:basis-12/12  lg:basis-4/12 ">
            {/* <img src="/assets/client_logo.jpg" alt="" className="bg-white" /> */}

            <LoginForm />
          </div>
          <Footer />
        </div>
      </div>
    </motion.div>
  );
};

export default LandingPage;
