import React, { useState } from "react";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { AiFillCopy } from "react-icons/ai";
import { motion } from "framer-motion";
import ReactQuill, { Quill } from "react-quill";

const Options = ({
  option,
  index,
  openDeleteModal,
  openCopyModal,
  openUpdateModal,
}) => {
  const [copyHover, setCopyHover] = useState(false);
  const copyMouseOver = () => {
    setCopyHover(true);
  };
  const copyMouseOut = () => {
    setCopyHover(false);
  };
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };
  return (
    <tr
      className={`rounded-lg text-black dark:text-white ${
        index % 2 === 0
          ? "bg-gray-50 dark:bg-gray-700"
          : "bg-white dark:bg-gray-500"
      }`}
    >
      {/* <td className="p-3 text-lg font-bold hover:underline cursor-pointer whitespace-nowrap">
        {option.id}
      </td> */}
      <td className="p-3 text-lg font-bold  bg-wc-green text-white sticky left-0 z-12">
        {option.name}
      </td>
      <td className="p-3 text-lg ">
        {" "}
        <ReactQuill
          disabled
          className="w-full rounded h-full bg-white text-black shadow-sm"
          value={option.description}
          readOnly={true}
          modules={{ toolbar: false }}
        />
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        <ul>
          {option.projectType.map((prj, index) => {
            if (prj === "NS") {
              return <li key={index}>new supply</li>;
            }
            if (prj === "IES") {
              return <li key={index}>increase existing supply</li>;
            }
            if (prj === "NF") {
              return <li key={index}>new facility</li>;
            }
            if (prj === "EF") {
              return <li key={index}>existing facility</li>;
            }
            return 0;
          })}
        </ul>
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        {option.avgAnnSupply.toLocaleString()}
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        {option.fCapacity.toLocaleString()}
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        {option.hydroVar}
      </td>

      <td className="p-3 text-lg  whitespace-nowrap border">
        $ {option.capitalCost.toLocaleString()}
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        $ {option.annualOM.toLocaleString()}
      </td>
      <td className="p-3 text-lg  whitespace-nowrap border">
        $ {option.annualWPC.toLocaleString()}
      </td>

      <td className="p-3 text-lg  wrap border">{option.prc}</td>
      <td className="p-3 text-lg  wrap border">{option.wqc}</td>
      <td className="p-3 text-lg  wrap border">{option.addInfo}</td>

      <td className="p-3 text-lg  whitespace-nowrap flex items-center justify-center gap-1">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            const optionData = {
              updateId: option.id,
              updateName: option.name,
              updateDesc: option.description,
              updatePt: option.projectType,
              updateAvgAnn: option.avgAnnSupply,
              updateFc: option.fCapacity,
              updateHv: option.hydroVar,
              updateCc: option.capitalCost,
              updateOm: option.annualOM,
              updateWplc: option.annualWPC,
              updatePrc: option.prc,
              updateWqc: option.wqc,
              updateAi: option.addInfo,
            };

            openUpdateModal(optionData);
          }}
          onMouseOver={editMouseOver}
          onMouseOut={editMouseOut}
        >
          <BsFillPencilFill size="18" className="cursor-pointer" />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openDeleteModal(option.id);
          }}
          onMouseOver={deleteMouseOver}
          onMouseOut={deleteMouseOut}
        >
          <BsFillTrashFill size="18" className="cursor-pointer" />
          {deleteHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              delete
            </div>
          )}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            const optionData = {
              copyName: option.name,
              copyDesc: option.description,
              copyPt: option.projectType,
              copyAvgAnn: option.avgAnnSupply,
              copyFc: option.fCapacity,
              copyHv: option.hydroVar,
              copyCc: option.capitalCost,
              copyOm: option.annualOM,
              copyWplc: option.annualWPC,
              copyPrc: option.prc,
              copyWqc: option.wqc,
              copyAi: option.addInfo,
            };
            openCopyModal(optionData);
          }}
          onMouseOver={copyMouseOver}
          onMouseOut={copyMouseOut}
        >
          <AiFillCopy size="18" className="cursor-pointer" />
          {copyHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              copy
            </div>
          )}
        </motion.button>
      </td>
    </tr>
  );
};

export default Options;
