import { React, useEffect, useState } from "react";
import { motion } from "framer-motion";

import { BsFillPencilFill } from "react-icons/bs";
import { useAlternativeOptionUpdate } from "../../context/AlternativeContext";

const Alternative = ({
  alternative,
  options,
  openUpdateAlternative,
  index,
  allOptions,
  allAltOptions,
  numAlts,
}) => {
  //cache logic
  const setAltOpts = useAlternativeOptionUpdate();
  const [altOpt, setAltOpt] = useState(null);
  const getAlternativeOption = () => {
    //grabbing alternative id by id and setting cache
    let altOptById = allAltOptions.filter((opt) => {
      return opt.altId === alternative.id;
    });
    setAltOpt(altOptById);
  };

  const [filteredAltOptions, setFilteredAltOptions] = useState([]);

  useEffect(() => {
    if (options) {
      console.log("options", options);
      const temp = [];
      for (const opt of options) {
        const altOpt = allAltOptions.filter((el) => {
          return el.optId === opt.id && el.altId === alternative.id;
        });
        temp.push(altOpt[0]);
      }
      console.log("tempFiltered", temp);
      setFilteredAltOptions(temp);
    }
  }, [allAltOptions, options]);

  let optAvgSum = [];
  let optTotal = 0;

  if (filteredAltOptions) {
    for (const opt of filteredAltOptions) {
      if (opt.checked === true) {
        const alt = allOptions.filter((el) => {
          return el.id === opt.optId;
        });
        if (alt.length > 0) {
          optAvgSum = [
            ...optAvgSum,
            { avgSupply: alt[0].avgAnnSupply, name: alt[0].name },
          ];
          console.log("optAvgSumAdd", optAvgSum);

          optTotal += alt[0].avgAnnSupply;
        }
      }
    }
    console.log("optAvgSum", optAvgSum);
  }

  //methods
  const toggleCheck = async (id, alt, opt, checked) => {
    //update gloabal state
    let updatedAltOpt = allAltOptions;
    for (const [i, v] of updatedAltOpt.entries()) {
      if (v.altId === alt && v.optId === opt) {
        updatedAltOpt[i].checked = !updatedAltOpt[i].checked;
      }
    }
    setAltOpts(updatedAltOpt);
    let altOptById = updatedAltOpt.filter((opt) => {
      return opt.altId === alternative.id;
    });
    setAltOpt(altOptById);
    ////////////
    /////////save to db
    let body = JSON.stringify({
      operation: null,
      query: `mutation toggleAltOption{ toggleAltOption(data:{altId:${alt}, optId:${opt}}){id,altId,optId,checked}}`,
    });

    await fetch("https://intplantoolapi.azurewebsites.net/api/alternative", {
      body: body,
      method: "POST",
    });
  };

  return (
    <table
      className={` shadow-md  w-[15rem] dark:bg-gray-500 ${
        numAlts - 1 === index && "rounded-r-md"
      }`}
    >
      <thead>
        {alternative && (
          <tr>
            <th className="p-3 text-lg font-semibold tracking-wide whitespace-wrap text-center bg-wc-green text-white w-full border-b-2 border-white">
              <div className="flex items-center justify-center gap-2 ">
                <span>{alternative.code}</span>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    openUpdateAlternative(alternative.id);
                  }}
                  className="hover:text-green-300"
                >
                  <BsFillPencilFill size="14" />
                </motion.button>
              </div>
            </th>
          </tr>
        )}
      </thead>
      <tbody className="divide-y ">
        {filteredAltOptions &&
          filteredAltOptions.map((option, index) => {
            return (
              <tr
                className={`${alternative.name}-${
                  option.id
                } flex justify-center ${option.checked ? "bg-green-200" : ""}`}
                key={index}
              >
                <td className="text-xl font-bold hover:underline cursor-pointer h-[5rem] text-center flex justify-center items-center">
                  <input
                    className={`${alternative.name}-${option.optId}`}
                    type="checkbox"
                    value={option.optId}
                    checked={option.checked}
                    onChange={() => {
                      toggleCheck(
                        option.id,
                        option.altId,
                        option.optId,
                        option.checked
                      );
                    }}
                  />
                </td>
              </tr>
            );
          })}
        <tr className="border-t-2 border-gray-200 bg-gray-50 dark:bg-gray-700 h-[20rem]">
          <td className="w-screen overflow-auto p-2 ">
            {" "}
            <ol className="flex flex-col justify-start gap-2 h-52 items-start content-start p-2 text-black dark:text-white font-semibold text-lg ">
              {optAvgSum.map((avg, index) => {
                // return <li key={index}>{`${avg.name}: ${avg.avgSupply}`}</li>;
                return (
                  <li
                    key={index}
                    className="text-wc-blue dark:text-white tracking-wider"
                  >{`${avg.name}`}</li>
                );
              })}
              <hr className="text-black w-full" />
              <span className="font-bold ">{`Total: ${optTotal}`}</span>
            </ol>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Alternative;
