import React, { createContext, useState, useContext } from "react";
import { UserProvider } from "./UserContext";
import { AlternativeProvider } from "./AlternativeContext";
import { ScenarioProvider } from "./ScenarioContext";
import { OptionProvider } from "./OptionContext";
import { CriteriaProvider } from "./CriteriaContext";
import { EvaluatedAlternativeProvider } from "./EvaluatedAlternativeContext";

const MainContext = createContext();

export const MainProvider = ({ children }) => {
  return (
    <>
      <UserProvider>
        <AlternativeProvider>
          <OptionProvider>
            <CriteriaProvider>
              <ScenarioProvider>
                <EvaluatedAlternativeProvider>
                  {children}
                </EvaluatedAlternativeProvider>
              </ScenarioProvider>
            </CriteriaProvider>
          </OptionProvider>
        </AlternativeProvider>
      </UserProvider>
    </>
  );
};
