import React, { createContext, useState, useContext } from "react";

const EvaluatedAlternativeContext = createContext();
const EvaluatedAlternativeUpdateContext = createContext();
const WeightedScoreContext = createContext();
const WeightedScoreUpdateContext = createContext();

export const useEvaluatedAlternative = () => {
  return useContext(EvaluatedAlternativeContext);
};

export const useEvaluatedAlternativeUpdate = () => {
  return useContext(EvaluatedAlternativeUpdateContext);
};

export const useWeightedScore = () => {
  return useContext(WeightedScoreContext);
};

export const useWeightedScoreUpdate = () => {
  return useContext(WeightedScoreUpdateContext);
};

export const EvaluatedAlternativeProvider = ({ children }) => {
  const [evaluatedAlternative, setEvaluatedAlternative] = useState(null);
  const updateEvaluatedAlternative = (evaluatedAlternative) => {
    setEvaluatedAlternative(evaluatedAlternative);
  };
  const [weightedScore, setWeightedScore] = useState([]);
  const updateWeightedScore = (ws) => {
    setWeightedScore(ws);
    console.log("scorres", weightedScore);
  };

  return (
    <>
      <EvaluatedAlternativeContext.Provider value={evaluatedAlternative}>
        <EvaluatedAlternativeUpdateContext.Provider
          value={updateEvaluatedAlternative}
        >
          <WeightedScoreContext.Provider value={weightedScore}>
            <WeightedScoreUpdateContext.Provider value={updateWeightedScore}>
              {children}
            </WeightedScoreUpdateContext.Provider>
          </WeightedScoreContext.Provider>
        </EvaluatedAlternativeUpdateContext.Provider>
      </EvaluatedAlternativeContext.Provider>
    </>
  );
};
