import React, { createContext, useState, useContext } from "react";

const UserContext = createContext();
const UserUpdateContext = createContext();

const LastUserIdContext = createContext();
const LastUserIdUpdateContext = createContext();

const UserLoadContext = createContext();
const UserLoadUpdateContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const useUserUpdate = () => {
  return useContext(UserUpdateContext);
};

export const useLastUserId = () => {
  return useContext(LastUserIdContext);
};

export const useLastUserIdUpdate = () => {
  return useContext(LastUserIdUpdateContext);
};

export const useUserLoad = () => {
  return useContext(UserLoadContext);
};

export const useUserLoadUpdate = () => {
  return useContext(UserLoadUpdateContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const updateUser = (user) => {
    setUser(user);
  };

  const [lastId, setLastId] = useState(null);
  const updateLastId = (id) => {
    setLastId(id);
  };

  const [load, setLoad] = useState(null);

  return (
    <>
      <UserContext.Provider value={user}>
        <UserUpdateContext.Provider value={updateUser}>
          <LastUserIdContext.Provider value={lastId}>
            <LastUserIdUpdateContext.Provider value={updateLastId}>
              <UserLoadContext.Provider value={load}>
                <UserLoadUpdateContext.Provider value={setLoad}>
                  {children}
                </UserLoadUpdateContext.Provider>
              </UserLoadContext.Provider>
            </LastUserIdUpdateContext.Provider>
          </LastUserIdContext.Provider>
        </UserUpdateContext.Provider>
      </UserContext.Provider>
    </>
  );
};
