export const OptionNameSort = (list) => {
  //get first word in each name (includes nums + strings)
  const sortList = list.map((el) => {
    return el.name.split(" ")[0];
  });
  //sorts alpha numeric values 1a,1b,2a,2b/ https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  sortList.sort(collator.compare);
  console.log("sort", sortList);

  let tempList = list.sort((a, b) => {
    return (
      sortList.indexOf(a.name.split(" ")[0]) -
      sortList.indexOf(b.name.split(" ")[0])
    );
  });

  return tempList;
};
