import React, { useState } from "react";
import { motion } from "framer-motion";

import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";

const Users = ({ user, index, openDeleteModal, openUpdateModal }) => {
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };
  return (
    <tr
      className={`rounded-lg dark:text-white text-black ${
        index % 2 === 0
          ? "bg-white dark:bg-gray-500"
          : "bg-gray-50 dark:bg-gray-700"
      }`}
    >
      <td className="p-3 text-sm font-bold text-blue-500 dark:text-white hover:underline cursor-pointer whitespace-nowrap">
        {user.id}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">{`${user.firstName} ${user.lastName}`}</td>
      <td className="p-3 text-sm whitespace-nowrap">{user.email}</td>
      <td className="p-3 text-sm whitespace-nowrap">{user.phone}</td>
      <td className="p-3 text-sm whitespace-nowrap">
        {user.role === "USER" && (
          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 dark:bg-yellow-400 rounded-lg bg-opacity-50">
            USER
          </span>
        )}
        {user.role === "ADMIN" && (
          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 dark:bg-green-400 rounded-lg bg-opacity-50 ">
            Admin
          </span>
        )}
        {user.role === "GUEST" && (
          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-gray-800 bg-gray-200 dark:bg-gray-50 rounded-lg bg-opacity-50 ">
            Guest
          </span>
        )}
        {user.role === "COUNTY" && (
          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-blue-800 bg-blue-200 dark:bg-blue-400 rounded-lg bg-opacity-50 ">
            AGENCY
          </span>
        )}
      </td>
      <td className="p-3 text-sm dark:text-white text-black whitespace-nowrap">
        {user.deleted ? "No" : "Yes"}
      </td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap flex gap-1">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            openUpdateModal({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: user.phone,
              deleted: user.deleted,
              role: user.role,
            });
          }}
          onMouseOver={editMouseOver}
          onMouseOut={editMouseOut}
        >
          <BsFillPencilFill
            size="18"
            className="cursor-pointer dark:text-white"
          />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        {user.role !== "ADMIN" && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              user.deleted
                ? alert("user is already inactive")
                : openDeleteModal(user.id);
            }}
            onMouseOver={deleteMouseOver}
            onMouseOut={deleteMouseOut}
          >
            <BsFillTrashFill
              size="18"
              className="cursor-pointer dark:text-white"
            />
            {deleteHover && (
              <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                delete
              </div>
            )}
          </motion.button>
        )}
      </td>
    </tr>
  );
};

export default Users;
