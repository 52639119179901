import { React, useEffect, useRef, useState } from "react";
import Options from "./Options";
import DeleteModal from "../Misc/DeleteModal";
import CopyModal from "./CopyOptionModal";
import UpdateModal from "./UpdateOptionModal";

import { AiOutlineFileAdd } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

import { OptionNameSort } from "../../utils/OptionSort";

const OptionsTable = ({
  addOption,
  closeAddOption,
  openAddOption,
  sidebarOpen,
  options_list,
  setOptions,
  allAltOptions,
  setAltOptions,
  allAlternatives,
}) => {
  const [sortedList, setSortedList] = useState(null);
  // const [filter, setFilter] = useState("name");
  //default filter
  useEffect(() => {
    const nameSort = OptionNameSort(options_list);
    setSortedList(nameSort);
  }, [options_list]);

  const adminDeleteOption = async (id) => {
    try {
      ////update global state
      const updatedOptions = options_list.filter((el) => {
        return el.id !== id;
      });
      setOptions(updatedOptions);
      const updatedAltOptions = options_list.filter((el) => {
        return el.optId !== id;
      });
      setAltOptions(updatedAltOptions);
      /////////
      ////udpate db
      let body = JSON.stringify({
        operation: null,
        query: `mutation deleteOption{deleteOption(id:${id}){id}}`,
      });

      await fetch("https://intplantoolapi.azurewebsites.net/api/option", {
        body: body,
        method: "POST",
      });
    } catch (err) {
      console.log(err);
    }
  };

  //delete
  const deleteId = useRef("");
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    deleteId.current = id;
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  //copy method
  const copyName = useRef("");
  const copyDesc = useRef("");
  const copyPt = useRef("");
  const copyAvgAnn = useRef("");
  const copyFc = useRef("");
  const copyHv = useRef("");
  const copyCc = useRef("");
  const copyOm = useRef("");
  const copyWplc = useRef("");
  const copyPrc = useRef("");
  const copyWqc = useRef("");
  const copyAi = useRef("");
  const copyId = useRef("");
  const [copyModal, setCopyModal] = useState(false);
  const openCopyModal = (option) => {
    copyName.current = option.copyName;
    copyDesc.current = option.copyDesc;
    copyPt.current = option.copyPt;
    copyAvgAnn.current = option.copyAvgAnn;
    copyFc.current = option.copyFc;
    copyHv.current = option.copyHv;
    copyCc.current = option.copyCc;
    copyOm.current = option.copyOm;
    copyWplc.current = option.copyWplc;
    copyPrc.current = option.copyPrc;
    copyWqc.current = option.copyWqc;
    copyAi.current = option.copyAi;
    setCopyModal(true);
  };
  const closeCopyModal = () => {
    setCopyModal(false);
  };
  //update modal
  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = (option) => {
    copyId.current = option.updateId;
    copyName.current = option.updateName;
    copyDesc.current = option.updateDesc;
    copyPt.current = option.updatePt;
    copyAvgAnn.current = option.updateAvgAnn;
    copyFc.current = option.updateFc;
    copyHv.current = option.updateHv;
    copyCc.current = option.updateCc;
    copyOm.current = option.updateOm;
    copyWplc.current = option.updateWplc;
    copyPrc.current = option.updatePrc;
    copyWqc.current = option.updateWqc;
    copyAi.current = option.updateAi;
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`option-table dark:bg-gray-900 min-h-full duration-400 ease-in-out transition-spacing font-roboto flex p-16 gap-4 ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      <div className="flex justify-between w-fit shadow-lg rounded-md h-[70rem]">
        <div className="flex flex-col gap-4 items-center  w-[15rem] text-black rounded bg-white dark:bg-gray-700">
          <h2 className="text-2xl font-semibold tracking-wide dark:text-white flex gap-4 items-center bg-wc-green p-4 rounded-t-md text-white">
            Option Management
            {/* <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-3 bg-wc-green text-white flex items-center gap-2 justify-center h-10"
              onClick={() => (addOption ? closeAddOption() : openAddOption())}
            >
              <AiOutlineFileAdd size="24" />
            </motion.button> */}
          </h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-3 bg-wc-green h-button text-white flex items-center gap-2 justify-center w-38"
            onClick={() => (addOption ? closeAddOption() : openAddOption())}
          >
            <AiOutlineFileAdd size="24" />
            Add Option
          </motion.button>

          {/* filter */}
          {/* <select
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          >
            <option value="name">Option Name</option>
            <option value="date">Oldest</option>
          </select> */}

          <hr className="w-10/12 text-black" />
          {sortedList && (
            <span className="text-black dark:text-black bg-blue-200 p-2 rounded-md">
              {`${sortedList.length} ${
                sortedList.length === 1 ? "option" : "options"
              }`}
            </span>
          )}
          {/* <hr className="w-10/12 text-black" />
          <span className="font-semibold tracking-wider dark:text-white">
            Totals
          </span>
          {options_list.length > 0 && (
            <div className="flex flex-col gap-4 p-2">
              <span className="text-black dark:text-black bg-green-200 rounded-md p-2">{`AASV Total: ${options_list
                .map((el) => {
                  return el.avgAnnSupply;
                })
                .reduce(function (prev, curr) {
                  return prev + curr;
                })
                .toLocaleString()}`}</span>

              <span className="text-black dark:text-black bg-yellow-200 p-2 rounded-md ">{`FC Total: $${options_list
                .map((el) => {
                  return el.fCapacity;
                })
                .reduce(function (prev, curr) {
                  return prev + curr;
                })
                .toLocaleString()}`}</span>

              <span className="text-black dark:text-black bg-gray-200 p-2 rounded-md ">{`CC Total: $${options_list
                .map((el) => {
                  return el.capitalCost;
                })
                .reduce(function (prev, curr) {
                  return prev + curr;
                })
                .toLocaleString()}`}</span>

              <span className="text-black dark:text-black bg-orange-200 p-2 rounded-md">{`O&M Total: $${options_list
                .map((el) => {
                  return el.annualOM;
                })
                .reduce(function (prev, curr) {
                  return prev + curr;
                })
                .toLocaleString()}`}</span>

              <span className="text-black dark:text-black bg-purple-200 p-2 rounded-md">{`WPC Total: $${options_list
                .map((el) => {
                  return el.annualWPC;
                })
                .reduce(function (prev, curr) {
                  return prev + curr;
                })
                .toLocaleString()}`}</span>
            </div>
          )} */}
        </div>

        <AnimatePresence exitBeforeEnter initial={false}>
          {deleteModal && (
            <DeleteModal
              handleClose={closeDeleteModal}
              heading={"Are you sure you want to delete this option?"}
              deleteFunc={adminDeleteOption}
              id={deleteId.current}
            />
          )}
          {copyModal && (
            <CopyModal
              handleClose={closeCopyModal}
              copyName={copyName.current}
              copyDesc={copyDesc.current}
              copyPt={copyPt.current}
              copyAvgAnn={copyAvgAnn.current}
              copyFc={copyFc.current}
              copyHv={copyHv.current}
              copyCc={copyCc.current}
              copyOm={copyOm.current}
              copyWplc={copyWplc.current}
              copyPrc={copyPrc.current}
              copyWqc={copyWqc.current}
              copyAi={copyAi.current}
              setOptions={setOptions}
              allOptions={options_list}
              allAlternatives={allAlternatives}
              setAltOptions={setAltOptions}
              allAltOptions={allAltOptions}
            />
          )}
          {updateModal && (
            <UpdateModal
              copyId={copyId.current}
              handleClose={closeUpdateModal}
              copyName={copyName.current}
              copyDesc={copyDesc.current}
              copyPt={copyPt.current}
              copyAvgAnn={copyAvgAnn.current}
              copyFc={copyFc.current}
              copyHv={copyHv.current}
              copyCc={copyCc.current}
              copyOm={copyOm.current}
              copyWplc={copyWplc.current}
              copyPrc={copyPrc.current}
              copyWqc={copyWqc.current}
              copyAi={copyAi.current}
              setOptions={setOptions}
              allOptions={options_list}
            />
          )}
        </AnimatePresence>

        {/* <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="rounded-md p-3 bg-wc-green h-button text-white flex items-center gap-2 justify-center w-48"
          onClick={() => (addOption ? closeAddOption() : openAddOption())}
        >
          <AiOutlineFileAdd size="24" />
          Add Option
        </motion.button> */}
      </div>

      <div className="rounded-lg shadow-lg overflow-x-scroll h-[70rem] bg-white dark:bg-gray-700 ">
        <table className="min-w-full shadow-md">
          <thead className="bg-wc-green border-b-2 border-gray-200 text-white sticky top-0 z-50">
            <tr>
              {/* <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-wrap">
                <p>id</p>
              </th> */}
              <th className="px-24 text-lg font-semibold tracking-wide text-left whitespace-nowrap ">
                <p>Option Name</p>
              </th>
              <th className="px-24 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Description
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Project Type
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Average Annual <p>Supply Volume(AFY)</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Facility Capacity(MGD)
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Hydrologic Variability
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Capital <p>Cost(Total)</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                O&M <p>Cost(Annual)</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Water Purchase or <p>Lease Cost(Annual per AF)</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Permitting and <p>Regulatory Considerations</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Water Quality <p>Considerations</p>
              </th>
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Additional info
              </th>
              {/* document column */}
              {/* <th className="p-3 text-lg font-semibod tracking-wide text-left whitespace-nowrap line-clamp-3">
                Document <p>Uploads</p>
              </th> */}
              <th className="p-3 text-lg font-semibold tracking-wide text-left whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>

          {sortedList && (
            <tbody className="divide-y">
              {sortedList.map((option, index) => {
                return (
                  <Options
                    option={option}
                    index={index}
                    key={index}
                    openDeleteModal={openDeleteModal}
                    openCopyModal={openCopyModal}
                    openUpdateModal={openUpdateModal}
                  ></Options>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </motion.div>
  );
};

export default OptionsTable;
