import React from "react";
import { motion } from "framer-motion";

const ProfileSettings = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-700 max-w-[1000px] h-full mx-auto duration-400 ease-in-out transition-spacing rounded-lg shadow-md p-12 font-roboto text-black dark:text-white"
    >
      <div className="w-fit mb-4 p-2 rounded flex items-center gap-2">
        <h2 className="text-2xl font-semibold ">Edit Profile</h2>
      </div>

      <div className="img-actions flex gap-4 items-center mb-8 ">
        <div className="profile-image flex flex-col justify-center items-center text-xl">
          <h3>Admin</h3>
          <img
            src={require("../../assets/icon.png")}
            alt="profile icon"
            className="rounded"
          />
        </div>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-white  bg-wc-green rounded cursor-pointer h-12 p-2 font-semibold"
        >
          Upload
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-black bg-wc-grey border-2 border-slate-400 rounded cursor-pointer h-12 p-2 font-semibold"
        >
          Remove
        </motion.button>
      </div>
      <hr />

      <div className="name-settings flex gap-12 my-8">
        <div className="display-name flex flex-col basis-6/12">
          <div className="display-name-labels flex ">
            <span className="basis-6/12 text-xl ">First name</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200 "
            placeholder="Enter first name"
          />
        </div>
        <div className="display-name flex flex-col basis-6/12">
          <div className="display-name-labels flex flex-row ">
            <span className="basis-6/12 text-xl">Last name</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="Enter last name"
          />
        </div>
      </div>
      <hr />
      <div className="name-settings flex gap-12 my-8">
        <div className="display-name flex flex-col basis-6/12">
          <div className="display-name-labels flex items-center">
            <span className="basis-6/12 text-xl">Email address</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="example@gmail.com"
          />
        </div>
        <div className="display-name flex flex-col basis-6/12 ">
          <div className="display-name-labels flex flex-row items-center ">
            <span className="basis-6/12 text-xl">Phone number</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="707-777-777"
          />
        </div>
      </div>
      <hr />
      <div className="flex flex-col mt-8">
        <label className="text-xl">Address</label>
        <input
          type="text"
          className="address-input w-full border-2 border-slate-400 h-text-input rounded bg-slate-50 dark:bg-gray-200"
          placeholder="Enter address"
        />
      </div>

      <div className="name-settings flex gap-12 my-4">
        <div className="display-name flex flex-col basis-6/12">
          <div className="display-name-labels flex items-center">
            <span className="basis-6/12 text-xl">City</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="Enter city"
          />
        </div>
        <div className="display-name flex flex-col basis-6/12 ">
          <div className="display-name-labels flex flex-row items-center ">
            <span className="basis-6/12 text-xl">State</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="Enter state"
          />
        </div>
      </div>
      <div className="name-settings flex gap-12 mb-8">
        <div className="display-name flex flex-col basis-6/12">
          <div className="display-name-labels flex items-center">
            <span className="basis-6/12 text-xl">Zip code</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="Enter zip code"
          />
        </div>
        <div className="display-name flex flex-col basis-6/12 ">
          <div className="display-name-labels flex flex-row items-center ">
            <span className="basis-6/12 text-xl">Country</span>
          </div>
          <input
            type="text"
            className="display-name-input border-slate-400 border-2 rounded h-text-input bg-slate-50 dark:bg-gray-200"
            placeholder="Enter country"
          />
        </div>
      </div>
      <hr />
      <div className="flex flex-col mt-8 items-left">
        <label className="text-xl ">Password</label>
        <input
          type="password"
          className="address-input border-2 border-slate-400 h-text-input rounded bg-slate-50 w-6/12 dark:bg-gray-200"
          placeholder="Enter password"
        />
      </div>
      <div className="flex justify-center">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="mt-12 border-slate-500 border-2 min-w-full h-button rounded bg-wc-green text-white text-xl "
        >
          Save
        </motion.button>
      </div>
    </motion.div>
  );
};

export default ProfileSettings;
