import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import Backdrop from "../Misc/Backdrop";
import { useLastUserIdUpdate, useLastUserId } from "../../context/UserContext";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddUserModal = ({ handleClose, text, setUsers, allUsers }) => {
  //local state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const lastUserId = useLastUserId();
  const setLastUserId = useLastUserIdUpdate();

  //methods
  const submit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        alert("passwords don't match");
      } else if (
        firstName.trim() === "" ||
        lastName.trim() === "" ||
        email.trim() === "" ||
        password.trim() === "" ||
        confirmPassword.trim() === "" ||
        phone.trim() === "" ||
        role.trim() === ""
      ) {
        alert("incomplete form");
      } else {
        ////update user global cache
        const currentId = lastUserId;
        if (currentId) {
          let updatedUsers = allUsers;
          updatedUsers.push({
            id: lastUserId + 1,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            phone: phone,
            role: role,
          });
          setUsers(updatedUsers);
          ///////
          handleClose();
        }
        ////save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation createUser{ createUser(data:{firstName:"${firstName}", lastName:"${lastName}", email:"${email}", password:"${password}", phone:"${phone}", role:${role}}){firstName,id}}`,
        });

        let req = await fetch(
          "https://intplantoolapi.azurewebsites.net/api/user",
          {
            body: body,
            method: "POST",
          }
        );

        let res = await req.text();
        setLastUserId(JSON.parse(res).data.createUser.id);
        const createdId = JSON.parse(res).data.createUser.id;
        console.log("updatedId", JSON.parse(res).data);
        if (!currentId) {
          let updatedUsers = allUsers;
          updatedUsers.push({
            id: createdId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            phone: phone,
            role: role,
          });
          setUsers(updatedUsers);
          ///////
          handleClose();
        }
        ////reset state
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setPhone("");
        setRole("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop>
      <motion.div
        className=" rounded-xl bg-gray-100 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-roboto w-[40rem]"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-12 flex flex-col gap-8 line-clamp-7 ">
          <div className=" rounded w-full p-2 flex gap-2 items-center justify-center ">
            <h2 className="text-2xl font-semibold text-center">
              Create a user{" "}
            </h2>
          </div>

          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="rounded basis-6/12 h-text-input border-2 border-gray-300"
              placeholder=" First name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <input
              type="text"
              className="rounded basis-6/12 border-2 border-gray-300 h-text-input"
              placeholder=" Last name"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="email text-black rounded h-text-input border-2 border-gray-300 basis-6/12"
              placeholder=" email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              type="text"
              className="phone text-black rounded h-text-input border-2 border-gray-300 basis-6/12"
              placeholder=" Phone"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>

          <input
            type="password"
            className="password text-black rounded h-text-input border-2 border-gray-300"
            placeholder=" Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <input
            type="password"
            className="confirm-password text-black rounded h-text-input border-2 border-gray-300"
            placeholder=" Confirm password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          <select
            name="role"
            id="role"
            className="h-text-input text-black rounded border-2 border-gray-300"
            onChange={(e) => {
              setRole(e.target.value);
            }}
          >
            <option defaultValue>Select user permssions</option>
            <option value="GUEST">Guest</option>
            <option value="USER">User</option>
            <option value="ADMIN">Admin</option>
            <option value="COUNTY">County</option>
          </select>
          <div className="flex flex-col gap-2">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-wc-green dark:bg-wc-grey text-white flex items-center justify-center h-button "
              onClick={submit}
            >
              SAVE
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-2 bg-gray-400 dark:bg-wc-grey text-white flex items-center justify-center h-button "
              onClick={handleClose}
            >
              CANCEL
            </motion.button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddUserModal;
