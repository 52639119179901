import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineFileAdd } from "react-icons/ai";
import Alternative from "./Alternative";

import { OptionNameSort } from "../../utils/OptionSort";

const AlternativeTable = ({
  sidebarOpen,
  openAddAlternative,
  openUpdateAlternative,
  allAlternatives,
  allOptions,
  allAltOptions,
}) => {
  const [filteredList, setFilteredList] = useState(null);
  useEffect(() => {
    const tempList = OptionNameSort(allOptions);
    setFilteredList(allOptions);
  }, [allOptions]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`alt-table dark:bg-gray-900 min-h-full duration-400 ease-in-out transition-spacing p-16 font-roboto flex gap-4 ${
        sidebarOpen ? "ml-[320px]" : "ml-[80px]"
      }`}
    >
      <div className="flex justify-between mb-4 w-fit rounded-md shadow-lg h-[70rem] bg-white dark:bg-gray-700">
        <div className="flex flex-col items-center gap-4  w-[15rem] text-black dark:text-white rounded ">
          <h2 className="text-2xl font-semibold tracking-wide flex gap-4 items-center bg-wc-green p-4 rounded-t-md text-white">
            Alternative Management
            {/* <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="rounded-md p-3 bg-wc-green h-10  text-white flex items-center justify-center "
              onClick={() => {
                allOptions.length > 0
                  ? openAddAlternative()
                  : alert("No options available, cannot create an alternative");
              }}
            >
              <AiOutlineFileAdd size="24" />
            </motion.button> */}
          </h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-3 bg-wc-green h-button text-white flex items-center justify-center w-38 gap-2"
            onClick={() => {
              allOptions.length > 0
                ? openAddAlternative()
                : alert("No options available, cannot create an alternative");
            }}
          >
            <AiOutlineFileAdd size="24" />
            Add Alternative
          </motion.button>

          <hr className="w-10/12 text-black" />

          {allAlternatives && (
            <span className="text-black dark:text-black bg-blue-200 p-2 rounded-md mb-4">
              {`${allAlternatives.length} ${
                allAlternatives.length === 1 ? "alternative" : "alternatives"
              }`}
            </span>
          )}
        </div>
      </div>

      {
        <div className="overflow-auto w-full mx-auto relative h-[70rem] b shadow-lg rounded-md bg-white dark:bg-gray-700">
          <div className="w-full flex gap-2">
            <table className="shadow-md w-2/12">
              <thead className="border-b-2 border-white">
                <tr>
                  <th className="p-3 text-md font-semibold tracking-wide text-center bg-wc-green text-white ">
                    Option
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {filteredList &&
                  filteredList.map((option, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className="text-md font-semibold text-white whitespace-nowrap bg-wc-green h-[5rem] text-center"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          {option.name}
                        </td>
                      </tr>
                    );
                  })}
                <tr className="h-[20rem] bg-wc-green text-white ">
                  <td className="flex justify-center p-4">
                    <span>Average Annual Supply Volume</span>
                  </td>
                </tr>
              </tbody>
            </table>

            {allAlternatives &&
              allAlternatives.map((alt, index) => {
                return (
                  <Alternative
                    key={index}
                    alternative={alt}
                    options={filteredList}
                    openUpdateAlternative={openUpdateAlternative}
                    index={index}
                    allOptions={filteredList}
                    allAltOptions={allAltOptions}
                    numAlts={allAlternatives.length}
                  />
                );
              })}
          </div>
        </div>
      }
    </motion.div>
  );
};

export default AlternativeTable;
