import { React, useState, useRef, useEffect } from "react";
import Backdrop from "../Misc/Backdrop";
import { motion } from "framer-motion";

import {
  useLastOptionId,
  useLastOptionIdUpdate,
} from "../../context/OptionContext";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const CopyOptionModal = ({
  handleClose,
  copyName,
  copyDesc,
  copyPt,
  copyAvgAnn,
  copyFc,
  copyHv,
  copyCc,
  copyOm,
  copyWplc,
  copyPrc,
  copyWqc,
  copyAi,
  setOptions,
  allOptions,
  allAlternatives,
  setAltOptions,
  allAltOptions,
}) => {
  const [name, setName] = useState(copyName);
  const [desc, setDesc] = useState(copyDesc);

  const lastOptionId = useLastOptionId();
  const setLastOptionId = useLastOptionIdUpdate();

  let projectTypes = useRef([]);
  const [nsChecked, setNsChecked] = useState(
    copyPt.includes("NS") ? true : false
  );
  useEffect(() => {
    nsChecked
      ? (projectTypes.current = [...projectTypes.current, "NS"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "NS"
        ));
  }, [nsChecked]);
  const [iesChecked, setIesChecked] = useState(
    copyPt.includes("IES") ? true : false
  );
  useEffect(() => {
    iesChecked
      ? (projectTypes.current = [...projectTypes.current, "IES"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "IES"
        ));
  }, [iesChecked]);
  const [nfChecked, setNfChecked] = useState(
    copyPt.includes("NF") ? true : false
  );
  useEffect(() => {
    nfChecked
      ? (projectTypes.current = [...projectTypes.current, "NF"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "NF"
        ));
  }, [nfChecked]);
  const [efChecked, setEfChecked] = useState(
    copyPt.includes("EF") ? true : false
  );
  useEffect(() => {
    efChecked
      ? (projectTypes.current = [...projectTypes.current, "EF"])
      : (projectTypes.current = projectTypes.current.filter(
          (el) => el !== "EF"
        ));
  }, [efChecked]);

  const [avgAnn, setAvgAnn] = useState(copyAvgAnn);
  const [Fc, setFc] = useState(copyFc);

  const [Hv, setHv] = useState("");
  const [annVar, setAnnVar] = useState(
    copyHv.includes("ANNUAL") ? true : false
  );
  const [seaVar, setSeaVar] = useState(
    copyHv.includes("SEASONAL") ? true : false
  );
  useEffect(() => {
    let hvar = "";
    annVar && (hvar = "ANNUAL");
    seaVar && (hvar = "SEASONAL");
    setHv(hvar);
  }, [annVar, seaVar]);

  const [Cc, setCc] = useState(copyCc);
  const [Om, setOm] = useState(copyOm);
  const [Wplc, setWplc] = useState(copyWplc);
  const [Prc, setPrc] = useState(copyPrc);
  const [Wqc, setWqc] = useState(copyWqc);
  const [Ai, setAi] = useState(copyAi);

  //methods
  const submit = async (e) => {
    try {
      e.preventDefault();

      if (name === copyName) {
        alert(
          "Invalid option name, name cannot be blank or equivalent to an exisiting option name"
        );
      } else if (
        name.trim() === "" ||
        desc.trim() === "" ||
        projectTypes.current.length === 0 ||
        avgAnn === null ||
        Fc === null ||
        Hv === "" ||
        annVar === null ||
        seaVar === null ||
        Cc === null ||
        Om === null ||
        Wplc === null ||
        Prc.trim() === "" ||
        Wqc.trim() === "" ||
        Ai.trim() === ""
      ) {
        alert("Missing required fields");
      } else {
        //update global state
        let updatedOptions = allOptions;
        updatedOptions.push({
          id: lastOptionId + 1,
          name: name,
          description: desc,
          projectType: projectTypes.current,
          avgAnnSupply: parseInt(avgAnn),
          fCapacity: parseInt(Fc),
          hydroVar: Hv,
          capitalCost: parseInt(Cc),
          annualOM: parseInt(Om),
          annualWPC: parseInt(Wplc),
          prc: Prc,
          wqc: Wqc,
          addInfo: Ai,
          creatorId: parseInt(localStorage.getItem("userId")),
        });
        setOptions(updatedOptions);
        if (allAlternatives.length > 0) {
          const updatedAltOpts = allAltOptions;
          for (const alt of allAlternatives) {
            const newAltOpts = {
              altId: alt.id,
              optId: lastOptionId + 1,
              checked: false,
            };
            updatedAltOpts.push(newAltOpts);
          }
          setAltOptions(updatedAltOpts);
        }
        //////////
        handleClose();
        /////save to db
        let body = JSON.stringify({
          operation: null,
          query: `mutation createOption{ createOption(data:{name:"${name}", description:"${desc}", projectType:[${
            projectTypes.current
          }], avgAnnSupply:${parseInt(avgAnn)}, fCapacity:${parseInt(
            Fc
          )},hydroVar:${Hv},capitalCost:${parseInt(Cc)}, annualOM:${parseInt(
            Om
          )}, annualWPC:${parseInt(
            Wplc
          )}, prc:"${Prc}", wqc:"${Wqc}", addInfo:"${Ai}", creatorId:${parseInt(
            localStorage.getItem("userId")
          )}}){id}}`,
        });

        let req = await fetch(
          "https://intplantoolapi.azurewebsites.net/api/option",
          {
            body: body,
            method: "POST",
          }
        );
        let res = await req.text();
        /////set id for added option to global state
        console.log("created", JSON.parse(res).data.createOption.id);
        setLastOptionId(JSON.parse(res).data.createOption.id);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="min-w-6/12 max-h-[800px] overflow-auto bg-gray-100 dark:bg-grey-gradient bg-cover dark:text-white shadow-md rounded-xl font-roboto text-wc-green"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="w-full p-12 flex flex-col gap-8 line-clamp-7">
          <div className="flex items-center justify-center ">
            <h2 className="text-2xl">Create new option from {copyName}</h2>
          </div>
          <div className="option-info flex flex-col gap-4 mb-4">
            <div className="option-name flex flex-col gap-2">
              <label className=" text-xl">Option Name</label>
              <input
                type="text"
                className="option-name-input rounded h-text-input text-black border-2 border-gray-300"
                placeholder=" Enter option name "
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="option-description flex flex-col gap-2">
              <label className=" text-xl">Description</label>
              <textarea
                rows="3"
                className="option-name-input rounded text-black border-2 border-gray-300"
                placeholder=" Enter option description"
                defaultValue={copyDesc}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />
            </div>
            <div className="project-type flex items-center gap-6 text-xl">
              <span>
                Project Type<p>(Check all that apply)</p>
              </span>
              <input
                id="new-supply"
                type="checkbox"
                className="w-6 h-6 border-2 border-gray-300"
                checked={nsChecked}
                value="NS"
                onChange={(e) => {
                  setNsChecked(!nsChecked);
                }}
              />
              <label htmlFor="new-supply">New Supply</label>
              <input
                id="increase-supply"
                type="checkbox"
                className="w-6 h-6 border-2 border-gray-300"
                checked={iesChecked}
                value="IES"
                onChange={(e) => {
                  setIesChecked(!iesChecked);
                }}
              />
              <label htmlFor="increase-supply">Increase Existing Supply</label>
              <input
                id="new-facility"
                type="checkbox"
                className="w-6 h-6 border-2 border-gray-300"
                checked={nfChecked}
                value="NF"
                onChange={(e) => {
                  setNfChecked(!nfChecked);
                }}
              />
              <label htmlFor="new-facility">New Facility</label>
              <input
                id="existing-facility"
                type="checkbox"
                className="w-6 h-6 border-2 border-gray-300"
                checked={efChecked}
                value="EF"
                onChange={(e) => {
                  setEfChecked(!efChecked);
                }}
              />
              <label htmlFor="existing-facility">Existing Facility</label>
            </div>
          </div>
          <div className="supply-volume flex flex-col border-gray-300 dark:border-white border-2 shadow p-6 rounded-xl gap-6 text-xl">
            <h3 className="font-semibold underline">
              Supply Volume and Facility Capacity
            </h3>
            <div className="flex gap-2">
              <div className="avg-annual-supply flex flex-col gap-2 rounded basis-4/12">
                <label htmlFor="aas-input">Average Annual Supply (AFY)</label>
                <input
                  id="aas-input"
                  type="text"
                  placeholder=" Enter number"
                  className="h-text-input rounded text-black border-2 border-gray-300"
                  defaultValue={copyAvgAnn}
                  onChange={(e) => {
                    setAvgAnn(e.target.value);
                  }}
                />
              </div>
              <div className="facility-capacity flex flex-col gap-2 basis-4/12">
                <label htmlFor="fc-input">Facility Capacity (MGD)</label>
                <input
                  id="fc-input"
                  type="text"
                  placeholder=" Enter number"
                  className="h-text-input rounded text-black border-2 border-gray-300"
                  defaultValue={copyFc}
                  onChange={(e) => {
                    setFc(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="hydrologic-variability flex gap-6 items-center ">
              <label htmlFor="hv-input">Hydrologic Variability : </label>
              <input
                type="checkbox"
                id="hv-input"
                className="w-6 h-6 border-2 border-gray-300"
                checked={annVar}
                onChange={(e) => {
                  setAnnVar(!annVar);
                  seaVar === true && setSeaVar(false);
                }}
              />
              <label>Annual Variability</label>
              <input
                type="checkbox"
                id="hv-input"
                className="w-6 h-6 border-2 border-gray-300"
                checked={seaVar}
                onChange={(e) => {
                  setSeaVar(!seaVar);
                  annVar === true && setAnnVar(false);
                }}
              />
              <label>Seasonal Variability</label>
            </div>
          </div>
          <div className="costs flex flex-col dark:border-white border-2 border-gray-300 shadow rounded-xl dark:text-white p-6 gap-4 text-xl ">
            <h3 className="font-semibold underline">Costs</h3>
            <div className="cost-inputs flex gap-2">
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">Caplital Cost(Total$)</label>
                <input
                  type="text"
                  placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-2 border-gray-300"
                  defaultValue={copyCc}
                  onChange={(e) => {
                    setCc(e.target.value);
                  }}
                />
              </div>
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">Annual O&M($/yr)</label>
                <input
                  type="text"
                  placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-2 border-gray-300"
                  defaultValue={copyOm}
                  onChange={(e) => {
                    setOm(e.target.value);
                  }}
                />
              </div>
              <div className="cost-field flex flex-col gap-1 basis-4/12">
                <label htmlFor="cc-input">
                  Annual Water Purchase Cost($/AFY)
                </label>
                <input
                  type="text"
                  placeholder=" Enter dollar amount"
                  className="h-text-input rounded text-black border-2 border-gray-300"
                  defaultValue={copyWplc}
                  onChange={(e) => {
                    setWplc(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col border-gray-300 dark:border-white shadow rounded-xl border-2 gap-4 p-4 text-xl">
            <h3 className="font-semibold underline">Other Considerations</h3>
            <div className="flex flex-col justify-between gap-2">
              <label htmlFor="prc-input basis-2/12">
                Permitting and Regulatory Considerations
              </label>
              <textarea
                id="prc-input"
                className="basis-10/12 rounded text-black border-2 border-gray-300"
                rows="3"
                placeholder=" Enter text"
                defaultValue={copyPrc}
                onChange={(e) => {
                  setPrc(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="prc-input basis-2/12">
                Water Quality Considerations
              </label>
              <textarea
                id="prc-input"
                className="w-full basis-10/12 rounded text-black border-2 border-gray-300"
                rows="3"
                placeholder=" Enter text"
                defaultValue={copyWqc}
                onChange={(e) => {
                  setWqc(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="prc-input basis-2/12">
                Additional Information
              </label>
              <textarea
                id="prc-input"
                className="w-full basis-10/12 rounded text-black border-2 border-gray-300"
                rows="3"
                placeholder=" Enter text"
                defaultValue={copyAi}
                onChange={(e) => {
                  setAi(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
            className="rounded-md p-2 bg-wc-blue text-white flex items-center justify-center m-2 h-button text-xl w-11/12 mx-auto"
            onClick={submit}
          >
            Save
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default CopyOptionModal;
