import React, { useState, useEffect } from "react";
import {
  useWeightedScore,
  useWeightedScoreUpdate,
} from "../../context/EvaluatedAlternativeContext";

import {
  useTotalAvgAnnSupply,
  useTotalAvgAnnSupplyUpdate,
  useTotalOm,
  useTotalOmUpdate,
  useTotalWaterPurchase,
  useTotalWaterPurchaseUpdate,
  useTotalWpc,
  useTotalWpcUpdate,
} from "../../context/AlternativeContext";

var pmt = require("formula-pmt");

const AlternativeSummary = ({
  alternative,
  allCriterias,
  index,
  allEvaluatedAlternatives,
  setEvaluatedAlternatives,
  scenarioId,
  scenarios,
  allOptions,
  allAltOptions,
  payPeriod,
  interestRate,
}) => {
  const [score, setScore] = useState("");
  const [scoreDesc, setScoreDesc] = useState("");
  const [evalId, setEvalId] = useState(0);

  const updateEvalAltScore = async () => {
    try {
      console.log("critId", evalId, "altId", alternative.id, "score", score);
      let body = JSON.stringify({
        operation: null,
        query: `mutation updateEvaluatedAlternative{ updateEvaluatedAlternative(data:{altId:${
          alternative.id
        }, critId:${evalId}, score:"${score}", scenarioId:${parseInt(
          scenarioId
        )}}){id}}`,
      });

      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative_evaluation",
        {
          body: body,
          method: "POST",
        }
      );

      body = JSON.stringify({
        operationName: null,
        query:
          "{allAlternativeEvaluations{id,score,description,critId,altId,scenario{name},deleted,scenarioId}}",
        variables: {},
      });
      req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative_evaluation",
        {
          body: body,
          method: "POST",
        }
      );

      let res = await req.text();
      setEvaluatedAlternatives(JSON.parse(res).data.allAlternativeEvaluations);
      console.log(
        "alsdfasdf",
        allEvaluatedAlternatives,
        alternative.id,
        evalId
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (scenarioId !== 0) {
      updateEvalAltScore();
    }
  }, [score]);

  //capital cost
  const [capitalCost, setCapitalCost] = useState(0);
  const [altOpt, setAltOpt] = useState(null);
  const getAlternativeOptions = () => {
    let altOptById = allAltOptions.filter((opt) => {
      return opt.altId === alternative.id;
    });
    setAltOpt(altOptById);
  };
  useEffect(() => {
    getAlternativeOptions();
  }, [allAltOptions]);

  let capitalCostSum = [];
  let capitalCostTotal = 0;

  if (altOpt) {
    for (const opt of altOpt) {
      if (opt.checked) {
        const alt = allOptions.filter((el) => {
          return el.id === opt.optId;
        });
        if (alt.length > 0) {
          capitalCostSum = [
            ...capitalCostSum,
            { capitalCost: alt[0].capitalCost, name: alt[0].name },
          ];

          capitalCostTotal += alt[0].capitalCost;
        }
      }
    }
  }

  const updateEvalAltDesc = async () => {
    try {
      let updatedEvalAlt = allEvaluatedAlternatives;
      console.log("allEva", updatedEvalAlt);
      for (const [i, v] of updatedEvalAlt.entries()) {
        if (
          v.critId === evalId &&
          v.altId === alternative.id &&
          v.scenarioId === parseInt(scenarioId)
        ) {
          updatedEvalAlt[i].description = scoreDesc;
        }
      }
      setEvaluatedAlternatives(updatedEvalAlt);
      let body = JSON.stringify({
        operation: null,
        query: `mutation updateEvaluatedAlternative{ updateEvaluatedAlternative(data:{altId:${
          alternative.id
        }, critId:${evalId}, description:"${scoreDesc}", scenarioId:${parseInt(
          scenarioId
        )}}){id}}`,
      });

      await fetch(
        "https://intplantoolapi.azurewebsites.net/api/alternative_evaluation",
        {
          body: body,
          method: "POST",
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (scenarioId !== 0) {
      updateEvalAltDesc();
    }
  }, [scoreDesc]);

  const [total, setTotal] = useState(null);
  const weightedScores = useWeightedScore();
  const setWeightedScores = useWeightedScoreUpdate();

  useEffect(() => {
    const allWeightedScores = weightedScores;
    if (allEvaluatedAlternatives) {
      const arr = allEvaluatedAlternatives.map((evalAlt, index) => {
        if (
          evalAlt.altId === alternative.id &&
          evalAlt.score.split(":")[0] !== ""
        ) {
          return {
            score: parseFloat(evalAlt.score.split(":")[0]),
            critId: evalAlt.critId,
            altId: evalAlt.altId,
          };
        }
        return { score: 0, critId: evalAlt.critId, altId: evalAlt.altId };
      });
      const temp = arr.filter((num) => {
        return num.altId === alternative.id;
      });
      if (temp.length > 0) {
        let sum = 0;
        for (const [index, elem] of temp.entries()) {
          sum +=
            elem.score *
            allCriterias.filter((el) => el.id === elem.critId)[0].weight;
          /////////
          temp[index].weight =
            elem.score *
            allCriterias.filter((el) => el.id === elem.critId)[0].weight;

          let updated = false;
          for (const [i, v] of allWeightedScores.entries()) {
            if (
              v.altId === temp[index].altId &&
              v.critId === temp[index].critId
            ) {
              if (temp[index]) {
                allWeightedScores[i] = temp[index];
              } else {
                allWeightedScores[i] = temp[index];
                allWeightedScores[i].weight = 0;
              }
              updated = true;
            }
          }
          if (!updated) {
            allWeightedScores.push(temp[index]);
          }
        }
        console.log("allWeightedScores", allWeightedScores);
        setWeightedScores([...allWeightedScores]);

        setTotal(sum);
      } else {
        setTotal(0);
      }
    }
  }, [allEvaluatedAlternatives, allCriterias, scenarioId]);

  //pmt functionality

  //calculate yearly payments using interest rate and pay period length
  //change 1 to 12 if you want monthly payments
  // const pmtMethod = (interest, periods) => {
  //   const iRate = interestRate / 100;
  //   console.log(
  //     "HILO",
  //     Math.abs(pmt(iRate / 1, payPeriod, capitalCostTotal).toFixed(2))
  //   );
  // };
  const totalAvgAnnSupply = useTotalAvgAnnSupply();
  const setTotalAvgAnnSupply = useTotalAvgAnnSupplyUpdate();
  const totalOm = useTotalOm();
  const setTotalOm = useTotalOmUpdate();
  // const totalWaterPurchase = useTotalWaterPurchase();
  // const setTotalWaterPurchase = useTotalWaterPurchaseUpdate();
  const totalWaterPurchase = useTotalWpc();
  const setTotalWaterPurchase = useTotalWpcUpdate();

  const [annualCapitalCost, setAnnualCapitalCost] = useState(null);
  const [capitalPerAcre, setCapitalPerAcre] = useState(null);
  const [omPerAcre, setOmPerAcre] = useState(null);
  const [wpcPerAcre, setWpcPerAcre] = useState(null);
  const [unitCostPerAcre, setUnitCostPerAcre] = useState(null);
  useEffect(() => {
    if (interestRate && payPeriod) {
      const iRate = parseInt(interestRate) / 100;
      let yearlyPayment = Math.abs(
        pmt(iRate / 1, parseInt(payPeriod), capitalCostTotal).toFixed(0)
      );
      setAnnualCapitalCost(Math.abs(yearlyPayment));
      const cpa = yearlyPayment / totalAvgAnnSupply[alternative.id];
      setCapitalPerAcre(cpa);
      const ompa = totalOm[alternative.id] / totalAvgAnnSupply[alternative.id];
      setOmPerAcre(ompa);
      console.log("ompa", ompa);
      console.log("pss", totalWaterPurchase);
      const wpcpa =
        totalWaterPurchase[alternative.id] / totalAvgAnnSupply[alternative.id];
      console.log("wpca", wpcpa);
      setWpcPerAcre(wpcpa);
      setUnitCostPerAcre(wpcpa + ompa + cpa);
    }
  }, [interestRate, payPeriod]);

  useEffect(() => {
    setTotalAvgAnnSupply(allOptions);
    setTotalOm(allOptions);
    setTotalWaterPurchase(allOptions);
  }, []);
  useEffect(() => {
    console.log(interestRate, payPeriod);
  }, [interestRate, payPeriod]);

  return (
    <tr
      className={`rounded-lg text-black dark:text-white text-center  ${
        index % 2 === 0
          ? "dark:bg-gray-700 bg-gray-50"
          : "dark:bg-gray-500 bg-white"
      }`}
    >
      <td className=" items-center text-lg font-bold  whitespace-nowrap bg-wc-green text-white sticky left-0">
        <div className="mb-2">{alternative.name}</div>
        <div>{`Total Capital Cost: $${capitalCostTotal.toLocaleString()}`}</div>
        {annualCapitalCost && parseInt(payPeriod) > 0 ? (
          <div className="flex flex-col gap-1">
            <span>{`Capital/AF: $${capitalPerAcre
              .toFixed(2)
              .toLocaleString()}`}</span>
            <span> {`O&M /AF $${omPerAcre.toFixed(2).toLocaleString()}`}</span>
            <span>{`WPC /AF: $${wpcPerAcre.toFixed(2).toLocaleString()}`}</span>
            <span>{`Total Unit Cost /AF: $${unitCostPerAcre
              .toFixed(2)
              .toLocaleString()}`}</span>
          </div>
        ) : (
          <span>Total Unit Cost /AF: $0</span>
        )}
      </td>

      {allCriterias &&
        allCriterias.map((criteria, index) => {
          return (
            <td className="p-4 border" key={index}>
              <div className="flex flex-col gap-2 justify-center items-center">
                <select
                  className="h-text-input w-60 text-black rounded border-2 border-gray-300"
                  onClick={(e) => {
                    if (parseInt(scenarioId) === 0) {
                      if (scenarios.length > 0) {
                        alert(
                          "a scenario must be selected before choosing score"
                        );
                      } else {
                        alert(
                          "please create a scenario before selecting a score"
                        );
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (scenarioId === 0) {
                      alert(
                        "a scenario must be selected before choosing score"
                      );
                    }

                    setScore(e.target.value);
                    setEvalId(criteria.id);
                  }}
                >
                  <option
                    selected
                    value=""
                    onChange={(e) => {
                      setScore(e);
                    }}
                  >
                    Please select a score
                  </option>
                  {allEvaluatedAlternatives &&
                    scenarioId !== 0 &&
                    criteria.scoring_assignment.map((assign, index) => {
                      return (
                        <option
                          key={index}
                          onChange={(e) => {
                            setScore(e);
                          }}
                          defaultValue={`${parseFloat(
                            criteria.scoring_points[index]
                          ).toFixed(2)}: ${assign}`}
                          selected={
                            `${parseFloat(
                              criteria.scoring_points[index]
                            ).toFixed(2)}: ${assign}` ===
                            `${
                              allEvaluatedAlternatives.filter(
                                (e) =>
                                  e.critId === criteria.id &&
                                  e.altId === alternative.id
                              ).length > 0
                                ? allEvaluatedAlternatives.filter(
                                    (e) =>
                                      e.critId === criteria.id &&
                                      e.altId === alternative.id
                                  )[0].score
                                : ""
                            }`
                          }
                        >
                          {`${parseFloat(
                            criteria.scoring_points[index]
                          ).toFixed(2)}: ${assign}`}
                        </option>
                      );
                    })}
                </select>

                {allEvaluatedAlternatives && (
                  <textarea
                    type="text"
                    rows="3"
                    className="text border-2 border-gray-400 w-60 dark:text-black"
                    value={`${
                      allEvaluatedAlternatives.filter(
                        (e) =>
                          e.critId === criteria.id && e.altId === alternative.id
                      ).length > 0
                        ? allEvaluatedAlternatives.filter(
                            (e) =>
                              e.critId === criteria.id &&
                              e.altId === alternative.id
                          )[0].description
                        : ""
                    }`}
                    onChange={(e) => {
                      const caret = e.target.selectionStart;
                      const element = e.target;
                      window.requestAnimationFrame(() => {
                        element.selectionStart = caret;
                        element.selectionEnd = caret;
                      });
                      setScoreDesc(e.target.value);
                      setEvalId(criteria.id);
                    }}
                    onClick={() => {
                      if (parseInt(scenarioId) === 0) {
                        if (scenarios.length > 0) {
                          alert(
                            "scenario must be selected before entering description"
                          );
                        } else {
                          alert(
                            "please create a scenario before entering description"
                          );
                        }
                      }
                    }}
                  />
                )}
              </div>
            </td>
          );
        })}

      <td>
        <span>{total}</span>
      </td>
      <td>
        <span>{total}</span>
      </td>
    </tr>
  );
};

export default AlternativeSummary;
