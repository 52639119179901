import React from "react";

const Footer = () => {
  return (
    <div className="w-full h-12 bg-slate-700 absolute bottom-0 flex">
      <div className="text-white flex gap-1 items-center justify-end basis-6/12">
        <span className="text-md">Contact by</span>
        <span className="text-orange-500 text-sm">Email</span>
      </div>
      <div className="text-white flex gap-2 items-center justify-end basis-6/12 pr-8">
        <span className="text-md">Powered By</span>
        <div className="flex flex-col text-xs">
          <span className="">Woodard</span>
          <span className="">& Curran</span>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Footer;
