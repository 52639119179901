import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useUserUpdate,
  useUserLoadUpdate,
  useUser,
} from "../../context/UserContext";

const LoginForm = () => {
  const navigate = useNavigate();
  const setUser = useUserLoadUpdate();
  const user = useUser();
  const setLoad = useUserLoadUpdate();
  //local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) alert("Missing Required Fields");
    try {
      let body = JSON.stringify({
        operation: null,
        query: `mutation loginUser{loginUser(email:"${email}", password:"${password}"){role, id, firstName, lastName, email}}`,
      });

      let req = await fetch(
        "https://intplantoolapi.azurewebsites.net/api/user",
        {
          body: body,
          method: "POST",
        }
      );
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, 2000);

      let res = await req.text();
      setUser(JSON.parse(res).data.loginUser);
      setLoad(true);
      console.log("usersspp", JSON.parse(res).data.loginUser);
      console.log("user", user);
      if (JSON.parse(res).data.loginUser) {
        navigate("dashboard");
        localStorage.setItem("userId", JSON.parse(res).data.loginUser.id);
        localStorage.setItem("role", JSON.parse(res).data.loginUser.role);
        localStorage.setItem(
          "firstName",
          JSON.parse(res).data.loginUser.firstName
        );
        localStorage.setItem(
          "lastName",
          JSON.parse(res).data.loginUser.lastName
        );
        localStorage.setItem("email", JSON.parse(res).data.loginUser.email);
      } else {
        navigate("/");
        alert("invalid userId");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center px-12 font-roboto bg-wc-gradient bg-cover gap-2">
      {/* <h1 className="text-white mr-auto text-4xl bold mb-8 font-semibold">
          Woodard & Curran
        </h1> */}
      {/* <div className="w-full p-4 bg-white rounded-md flex justify-center items-center absolute top-0 h-[5rem]">
        <div className="h-[5rem] w-6/12 bg-palmdale-logo bg-contain bg-no-repeat" />
        <h2 className="text-xl font-extrabold tracking-wider text-wc-blue ">
          Powered by<p>Woodard & Curran</p>{" "}
        </h2>
      </div> */}

      <form
        className="flex flex-col border-solid border-2 rounded-xl px-28 pt-10 pb-20 w-full shadow-2xl bg-white mb-4 text-white"
        onSubmit={onSubmit}
      >
        <div className="h-[7rem] w-4/12 bg-client-logo bg-contain bg-no-repeat mx-auto"></div>
        <h2 className="font-semibold mb-4 text-2xl text-wc-green">
          Sign in to your account
        </h2>
        <div className="email flex flex-col text-left text-lg text-wc-green">
          <label className="text-xl">Email</label>
          <input
            type="text "
            className="w-full border-2  mb-6 h-text-input rounded text-black pl-2"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="password flex flex-col text-left text-wc-green">
          <div className="flex items-center">
            <label className="text-xl">Password</label>
            <span className="ml-auto text-wc-blue text-sm cursor-pointer ">
              Forgot your password?
            </span>
          </div>
          <input
            type="password"
            className="w-full border-2 h-text-input rounded mb-14 text-black pl-2"
            placeholder="Enter password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <button
            className="border-black border-0 bg-wc-gradient rounded-lg h-button text-white cursor-pointer hover:opacity-80 "
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
      {/* <span className="mr-auto mt-4 text-lg text-white">
          Don't have an account?{" "}
          <span className="text-white cursor-pointer text-lg">Sign up</span>
        </span> */}
    </div>
  );
};

export default LoginForm;
